<template>
  <div class="question-container">
    <div class="question-heading">
      <font-awesome-icon
        icon="fa-solid fa-question"/>
      <p>{{ textQuestion }}</p>
    </div>
    <div>
      <p class="answer-item">{{ question.answer }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReflexionGuidelineTextFieldResponse',
  props: {
    question: Object,
  },
  components: {},
  computed: {
    textQuestion() {
      if (this.$i18n.locale === 'de') {
        return this.question.question_de;
      }
      return this.question.question_en;
    },
  },
};
</script>
<style scoped>
  .question-container {
    user-select: none;
    width: 100%;
    height: 100%;
  }
  .question-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
  }

  .question-heading p {
    margin-left: 10px;
    margin-right: 25px;
  }

  .answer-item {
    min-height: 100px;
    width: 95%;
    background-color: rgba(255, 194, 102, 0.7);
    color: var(--color-black);
    padding: 5px 10px;
    border-radius: 15px;
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    .question-container {
      padding-left: 5px;
    }
  }

  @media (max-width: 425px) {
    .question-container {
      padding-left: 0px;
    }
  }

</style>
