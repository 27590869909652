<template>
  <div class="mainWeeklyReport">
    <div class="weeklyReports">
      <div class="topReports">
        <h1>{{ $t("mngDashboardComponent.yourWeeklyReport") }}</h1>
        <div class="switchButton">
          <div class="checkboxContainer">
            <input
              type="checkbox"
              id="mySwitch"
              class="switch-input switchCase"
              value="isRelative"
              @input="toggleHandler"
            />
            <label for="mySwitch" class="switch-label"></label>
            <span> {{ $t("mngDashboardComponent.relativeData") }}</span>
          </div>
          <button @click="downloadCSV(weeklyReports?.csv)">
            <img src="@/assets/download-csv.svg" alt="download csv" />

            {{ $t("mngDashboardComponent.csvDownload") }}
          </button>
        </div>
      </div>

      <div class="bottom-reports">
        <!-- ----------- Performance ------------ -->
        <div class="performance">
          <h3>{{ $t("mngDashboardComponent.performance") }}</h3>
          <div class="performanceValues">
            <TrendValues
              :mainValue="formatMillisecondsToTime(weeklyReports?.report?.performance_avgTimeSpent)"
              :trendValue="weeklyReports?.report?.performance_avgTimeSpentTrend"
              trendValuesClassName="timeSpent"
              :avgText="$t('mngDashboardComponent.avgTimespent')"
            />

            <TrendValues
              :mainValue="formatMillisecondsToTime(weeklyReports?.report?.performance_avgOvertime)"
              :trendValue="weeklyReports?.report?.performance_avgOvertimeTrend"
              trendValuesClassName="overTime"
              :avgText="$t('mngDashboardComponent.avgOvertime')"
            />
          </div>
        </div>
        <!-- ----------- Activity------------ -->
        <div class="activity">
          <h3>{{ $t("mngDashboardComponent.activity") }}</h3>
          <div class="activityValues">
            <TrendValues
              :mainValue="weeklyReports?.report?.activity_avgLogins"
              :trendValue="weeklyReports?.report?.activity_avgLoginsTrend"
              trendValuesClassName="timeSpent"
              :avgText="$t('mngDashboardComponent.avgLogin')"
            />
            <TrendValues
              :mainValue="weeklyReports?.report?.activity_activeUsers"
              :trendValue="weeklyReports?.report?.activity_activeUsersTrend"
              trendValuesClassName="overTime"
              :avgText="$t('mngDashboardComponent.activeUsers')"
            />
          </div>
        </div>
        <!-- ----------- 360 Mindset------------ -->
        <div class="mindSet">
          <h3>{{ $t("mngDashboardComponent.360OMindset") }}</h3>
          <div class="mindSetValues">
            <TrendValues
              :mainValue="weeklyReports?.report?.['360mindset_sent']"
              :trendValue="weeklyReports?.report?.['360mindset_sentTrend']"
              trendValuesClassName="sent"
              :avgText="$t('mngDashboardComponent.sent')"
            />
            <TrendValues
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.['360mindset_submitted']
                  : weeklyReports?.report?.relative360mindset_submitted
              "
              :trendValue="weeklyReports?.report?.['360mindset_submittedTrend']"
              trendValuesClassName="submitted"
              :avgText="$t('mngDashboardComponent.submitted')"
              :isRelative="isRelative"
            />
            <TrendValues
              :reverse="true"
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.['360mindset_sentExpired']
                  : weeklyReports?.report?.relative360mindset_sentExpired
              "
              :trendValue="weeklyReports?.report?.['360mindset_sentExpiredTrend']"
              trendValuesClassName="expired"
              :avgText="$t('mngDashboardComponent.expired')"
              :isRelative="isRelative"
            />
          </div>
        </div>
        <!-- ----------- Collaboration Tracker------------ -->
        <div class="collabration">
          <h3>{{ $t("mngDashboardComponent.collaborationTracker") }}</h3>
          <div class="collabrationValues">
            <TrendValues
              :mainValue="weeklyReports?.report?.collaboration_created"
              :trendValue="weeklyReports?.report?.collaboration_createdTrend"
              trendValuesClassName="created"
              :avgText="$t('mngDashboardComponent.created')"
            />
            <TrendValues
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.collaboration_submitted
                  : weeklyReports?.report?.relativeCollaboration_submitted
              "
              :trendValue="weeklyReports?.report?.collaboration_submittedTrend"
              trendValuesClassName="submitted"
              :avgText="$t('mngDashboardComponent.submitted')"
              :isRelative="isRelative"
            />

            <TrendValues
              :reverse="true"
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.collaboration_expired
                  : weeklyReports?.report?.relativeCollaboration_expired
              "
              :trendValue="weeklyReports?.report?.collaboration_expiredTrend"
              trendValuesClassName="expired"
              :avgText="$t('mngDashboardComponent.expired')"
              :isRelative="isRelative"
            />
          </div>
        </div>

        <!-- ------------- Mood-o-Mat------------ -->
        <div class="moodMat">
          <h3>{{ $t("mngDashboardComponent.mood-o-mat") }}</h3>
          <div class="moodMatValues">
            <TrendValues
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.moodomat_submitted
                  : weeklyReports?.report?.relativeMoodomat_submitted
              "
              :trendValue="weeklyReports?.report?.moodomat_submittedTrend"
              trendValuesClassName="submitted"
              :avgText="$t('mngDashboardComponent.submitted')"
              :isRelative="isRelative"
            />
            <TrendValues
              :mainValue="weeklyReports?.report?.moodomat_avgSatisfaction"
              :trendValue="weeklyReports?.report?.moodomat_avgSatisfactionTrend"
              trendValuesClassName="satisfaction"
              :avgText="$t('mngDashboardComponent.avgSatisfaction')"
              :isRelative="isRelative"
              isPercentageValue
            />
          </div>
        </div>

        <!-- ------------- Development Tips------------ -->
        <div class="developmentTips">
          <h3>{{ $t("mngDashboardComponent.developmentTips") }}</h3>
          <div class="developmentValues">
            <TrendValues
              :mainValue="weeklyReports?.report?.development_avgChecked"
              :trendValue="weeklyReports?.report?.development_avgCheckedTrend"
              trendValuesClassName="avgChecked"
              :avgText="$t('mngDashboardComponent.avgChecked')"
            />
            <TrendValues
              :mainValue="weeklyReports?.report?.development_avgFeedback"
              :trendValue="weeklyReports?.report?.development_avgFeedbackTrend"
              trendValuesClassName="feedback"
              :avgText="$t('mngDashboardComponent.avgFeedback')"
              :isRelative="isRelative"
              isPercentageValue
            />
          </div>
        </div>
      </div>
    </div>
    <div class="usersTeams">
      <div class="users">
        <div class="headingIcon">
          <h6>{{ $t("mngDashboardComponent.totalUsers") }}</h6>
          <img src="@/assets/user-group.svg" alt="user-group" />
        </div>
        <h1>{{ weeklyReports?.report?.totalUsers }}</h1>
        <h2>{{ $t("mngDashboardComponent.vsLastQuarter")}}
           {{ weeklyReports?.report?.totalUsersLastQuarter }}</h2>
      </div>
      <div class="teams">
        <div class="headingIcon">
          <h6>{{ $t("mngDashboardComponent.totalTeams") }}</h6>
          <img src="@/assets/team.svg" alt="team" />
        </div>
        <h1>{{ weeklyReports?.report?.totalTeams }}</h1>
        <h2>{{ $t("mngDashboardComponent.vsLastQuarter")}}
           {{ weeklyReports?.report?.totalTeamsLastQuarter }}</h2>
      </div>
    </div>
  </div>
</template>
<script>
import TrendValues from '@/components/Home/ManagementDashboard/WeeklyReportComponent/TrendValuesComponent.vue';
import './weeklyReport.css';

export default {
  name: 'WeeklyReportComponent',
  data() {
    return {};
  },
  components: {
    TrendValues,
  },

  computed: {
    weeklyReports() {
      return this.$store.getters.getWeeklyReport.data;
    },

    isRelative() {
      return this.$store.getters.getWeeklyReport.isRelative;
    },
  },

  methods: {
    // --------------- Toggle Handler -------------------- //
    toggleHandler() {
      this.$store.commit('setIsRelative', !this.isRelative);

      this.fetchInitialWeeklyReport();
    },

    // ------------ Weekly Report Handler --------------- //
    fetchInitialWeeklyReport() {
      try {
        this.$store.dispatch('fetchInitialWeeklyReport');
      } catch (error) {
        console.error(error);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
      }
    },

    // ------------- CSV download Handler ------------------ //
    downloadCSV(csv) {
      // Create a Blob object containing the CSV data
      const blob = new Blob([csv], { type: 'text/csv' });

      // Create a temporary link element for the download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'report.csv'; // Specify the desired file name

      // Trigger a click event on the link to initiate the download
      a.click();

      // Cleanup: Remove the temporary link and revoke the Blob URL
      URL.revokeObjectURL(a.href);
    },

    // ------------- Format MilliSecond Handler ------------------ //
    formatMillisecondsToTime(ms = 0) {
      const totalMinutes = ms / (60 * 1000);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.round(totalMinutes % 60);

      if (hours === 0) {
        return `${totalMinutes.toFixed(1)}min`;
      }
      return `${hours}hr ${minutes}min`;
    },
  },

  mounted() {
    this.fetchInitialWeeklyReport();
  },
};
</script>
