<template>
  <div class="team-selection-container">
    <div class="forms">
      <img class="platform-logo" src="@/assets/logo_transparent.png" alt="txt">
      <h1>{{ $t('TeamSelectionOverlayComponent.chooseYourTeam') }}</h1>
      <vee-form @submit="select">
        <div class="select-container">
          <ul class="list-items" id="tpl_teams-container">
            <li :class="{ item: !isChecked(team),
             'item checked': isChecked(team)}"
                v-for="team in teams" :key="team"
                @click.prevent="toggleSelection(team)">
              <span class="item-text">
                {{ team.name }}
              </span>
              <span class="checkbox">
                <i class="check-icon">
                  <font-awesome-icon icon="fa-circle-check"/>
                </i>
              </span>
            </li>
          </ul>
        </div>
        <button type="submit" :disabled="buttonDisabled" class="button" name="regist"
                id="btn_regist">
          {{ $t('TeamSelectionOverlayComponent.select') }}
        </button>
      </vee-form>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TeamSelectionOverlayComponent',
  props: {
    overlay: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      buttonDisabled: false,
    };
  },
  computed: {
    teams() {
      return this.$store.getters.getTeams;
    },
    selectedTeams() {
      return this.$store.state.content.selectedTeams;
    },
  },
  methods: {
    toggleSelection(team) {
      this.$store.commit('toggleTeamSelection', team._id);
    },
    isChecked(team) {
      return this.$store.state.content.selectedTeams.includes(team._id);
    },
    async select() {
      try {
        this.buttonDisabled = true;
        await this.$store.dispatch('setTeams', {
          id: this.overlay._id,
          teams: this.selectedTeams,
        });
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('TeamSelectionOverlayComponent.success'),
          type: 'success',
        });
        this.$store.dispatch('resetTeams');
        this.buttonDisabled = false;
      } catch (error) {
        this.buttonDisabled = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch('getTeams');
  },
  unmounted() {
    this.$store.commit('setSelectedTeams', []);
    this.$store.commit('setTeams', []);
  },
};
</script>

<style scoped>

.team-selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  width: 80%;
  max-width: 50rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.forms {
  width: 100%;
}

/*---------------- GENERAL ------------------*/

.platform-logo {
  display: block;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 10rem;
  height: auto;
}

h1 {
  text-align: center;
  font-family: "Poppins";
  font-size: 25px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  color: var(--color-dark);
  /*padding: var(--card-padding) 0 var(--card-padding);*/
}

h2 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  color: var(--color-dark);
}

h3 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  color: var(--color-dark);
}

h4 {
  padding: 0.5rem 0;
}

p {
  padding: 0.5rem 0;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

.photo { /*gilt für alle Fotos*/
  display: block;
  width: 100%;
}

.pass {
  float: left;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 600;
  padding: 15px 10px;
}

.pass:hover {
  color: var(--color-fulvous);
}

.pass i {
  padding: 0 .5rem;
}

.select-container {
  position: relative;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.select-container .list-items {
  width: 100%;
  max-height: 280px;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem 0.5rem;
  margin: 1rem 0;
  background-color: var(--color-milk);
  display: block;
  overflow: hidden;
  overflow-y: auto;
  z-index: 10;
}

.select-container .list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
  padding: 0 1rem;
}

.select-container .list-items .item:hover {
  transform: scale(1.02);
}

.select-container .list-items .item .item-text {
  display: flex;
  align-items: center;
}

.select-container .list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.select-container .list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 21px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.item.checked .check-icon {
  transform: scale(1);
}

.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=submit]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

button[type=submit]:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .select-container .list-items .item {
      padding: 0 0.5rem;
      column-gap: 0.2rem;
      height: auto;
      margin: 0.5rem 0;
      font-size: 13px;
    }

    .select-container .list-items {
      overflow-x: auto;
    }
}

</style>
