<template>
  <div class="popup-overview">
    <button class="close-btn" @click="closePopup">
      <font-awesome-icon icon="fa-xmark" />
    </button>
    <div class="date-container">
      <input type="datetime-local" class="date-input" v-model="formattedDate" />
      <div>
        <button @click="createTask" class="button">
          <p>{{ $t("UserAdministrationComponent.options.createAssessment") }}</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkAdmin from '@/helpers/networking/NetworkAdmin';

export default {
  name: 'CreateAssessmentCalendarOverlayComponent',
  props: {
    data: Object,
  },
  data() {
    return {
      date: new Date(),
    };
  },
  computed: {
    formattedDate: {
      get() {
        const localDate = new Date();

        const timeZoneOffset = localDate.getTimezoneOffset();

        localDate.setMinutes(localDate.getMinutes() - timeZoneOffset);

        return localDate.toISOString().slice(0, 16);
      },
      set(value) {
        this.date = value;
      },
    },
  },
  methods: {
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    async createTask() {
      try {
        const postData = {
          userIDs: [...this.data.userIDs],
          schemaName: this.data?.schemaName,
          schemaID: this.data?.schemaID,
          startingTime: new Date(this.formattedDate).toUTCString(),
          forceSimilarTask: true,
        };
        await NetworkAdmin.createTask(postData);

        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('UserAdministrationComponent.toast.successfulTaskCreation'),
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('UserAdministrationComponent.toast.errorTaskCreation'),
          type: 'error',
        });
      }
      this.closePopup();
    },
  },
};
</script>

<style scoped>
.popup-overview {
  font-size: 12px;
  background-color: var(--color-milk);
  padding: 1em;
  border-radius: 2em;
  line-height: 1.5em;
  box-shadow: 4px 5px 0 3px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  max-width: 90%;
  max-height: 22%;
  top: 150px;
  position: absolute;
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 12px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  color: black;
}

.close-btn:hover {
  color: var(--color-orange);
}

.button {
  display: block;
  margin-top: 0.5rem;

  padding: 10px 35px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
  width: 100%;
}

.delete-button {
  color: var(--color-orange);
  cursor: pointer;
}

.date-input {
  font-family: "Poppins";
  border: 1px solid var(--color-orange);
}

.date-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 200px;
}
</style>
