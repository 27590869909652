<template>
  <div class="forms">
    <vee-form @submit="createKssTask">
      <div class="time-wrapper">
        <div class="time-box">
          <div class="left-side" v-if="step === 1">
            <div class="question">
              <label class="question-label">
                <font-awesome-icon icon="fa-pen" class="question-icon"/>
                {{ $t('PersonalGoals.qGoalName') }}
              </label>
              <input
                :class="{'goalNameChanged': this.goalNameChanged,
                        'noGoalNameChange' : !this.goalNameChanged}"
                class="answers" type="text" ref="goalName" maxlength="1000"
                :placeholder="$t('PersonalGoals.placeholder')" v-model="goalName"/>
            </div>
            <div class="question">
              <label class="question-label">
                <font-awesome-icon icon="fa-flag-checkered" class="question-icon"/>
                {{ $t('PersonalGoals.qGoalType') }}
              </label>
              <select v-model="goalType">
                <option :value="this.$t('PersonalGoals.goalType.habit')">
                  {{ $t('PersonalGoals.goalType.habit') }}
                </option>
                <option :value="this.$t('PersonalGoals.goalType.mindset')">
                  {{ $t('PersonalGoals.goalType.mindset') }}
                </option>
                <option :value="this.$t('PersonalGoals.goalType.reflection')">
                  {{ $t('PersonalGoals.goalType.reflection') }}
                </option>
                <option :value="this.$t('PersonalGoals.goalType.other')">
                  {{ $t('PersonalGoals.goalType.other') }}
                </option>
              </select>
            </div>
          </div>
          <div class="spacer" v-if="step === 1"></div>
          <div class="right-side" v-if="step === 1">
            <div class="area" id="div_goals-info">
              <p><b>{{ this.$t('PersonalGoals.goalType.habit') }}:</b>
                {{ this.$t('PersonalGoals.desc.habit') }}</p>
              <p><b>{{ this.$t('PersonalGoals.goalType.mindset') }}:</b>
                {{ this.$t('PersonalGoals.desc.mindset') }}</p>
              <p><b>{{ this.$t('PersonalGoals.goalType.reflection') }}:</b>
                {{ this.$t('PersonalGoals.desc.reflection') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="full-width" v-if="step === 2">
        <div class="question">
          <label class="question-label">
            <font-awesome-icon icon="fa-bullseye" class="question-icon"/>
            {{ $t('PersonalGoals.qSpecific') }}
          </label>
          <input maxlength="500" class="answers" type="text" ref="specific"
                 :placeholder="$t('PersonalGoals.placeholder')" v-model="specific"/>
        </div>
        <div class="question">
          <label class="question-label">
            <font-awesome-icon icon="fa-clock" class="question-icon"/>
            {{ $t('PersonalGoals.qMeasurable') }}
          </label>
          <input maxlength="500" class="answers" type="text" ref="measurable"
                 :placeholder="$t('PersonalGoals.placeholder')" v-model="measurable"/>
        </div>
        <div class="question">
          <label class="question-label">
            <font-awesome-icon icon="fa-certificate" class="question-icon"/>
            {{ $t('PersonalGoals.qAchievable') }}
          </label>
          <input maxlength="500" class="answers" type="text" ref="achievable"
                 :placeholder="$t('PersonalGoals.placeholder')" v-model="achievable"/>
        </div>
        <div class="question">
          <label class="question-label">
            <font-awesome-icon icon="fa-magnifying-glass" class="question-icon"/>
            {{ $t('PersonalGoals.qRelevant') }}
          </label>
          <input maxlength="500" class="answers" type="text" ref="relevant"
                 :placeholder="$t('PersonalGoals.placeholder')" v-model="relevant"/>
        </div>
        <div class="question">
          <label class="question-label">
            <font-awesome-icon icon="fa-calendar" class="question-icon"/>
            {{ $t('PersonalGoals.qGoalDate') }}
          </label>
          <div class="time-wrapper">
            <div class="time-box">
              <label for="cbox1" class="label" id="lbl_calDate" @click.prevent="toggleShowCalendar">
                <font-awesome-icon icon="fa-calendar"/>
                {{ formattedStartDate }}
              </label>
              <div class="area" id="div_calendar-date" v-if="getShowCalendar">
                <edit-kss-calendar-component :editable="editable" :initialDate="selectedDate"
                                             @updateDate="updateSelectedDate"/>
              </div>
            </div>
          </div>
        </div>
        <div class="question" v-if="this.goalType === 'Habit'">
          <label class="question-label">
            <font-awesome-icon icon="fa-bell" class="question-icon"/>
            {{ $t('PersonalGoals.setReminder') }}
          </label>
          <div class="runtime" id="div_runtime">
            <div class="timer">
              <vee-field v-model="time"
               type="time"
               class="time"
               name="start-time"
               id="input_cal-time"/>
            </div>
            <div class="drop_event" id="drop_event">
              <div class="seven_days" id="seven_days">
                <label id="day" v-for="(day, index) in getDays" :key="day.name"
                  @click="selectDay(index)">
                  <div class="content-day" :class="{checked: day.isSelected}">
                    <span>{{ $t('PersonalGoals.daysShort.' + day.rSchName) }}</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <button v-if="step === 1" type="button" class="btn-create" name="next"
                id="btn_next" @click="checkForErrorsStepOne" :disabled="disableNextButton">
          {{ $t('PersonalGoals.nextButton') }}
        </button>
        <button v-if="step === 2" type="button" class="btn-create" name="back"
                id="btn_back" @click="step = 1">
          {{ $t('PersonalGoals.backButton') }}
        </button>
        <button v-if="step === 2" type="button" class="btn-create" name="create"
                id="btn_createGoals" @click.prevent="createPersonalGoal">
          {{ $t('PersonalGoals.createGoal') }}
        </button>
      </div>
    </vee-form>
  </div>
  <pop-up-confirmation-component v-if="showPopup" :message="popupMessage"
                                 :confirmButtonText="continueButtonText"
                                 :cancelButtonText="cancelButtonText"
                                 @cancel="onPopupCancel" @confirm="onPopupConfirm"/>
</template>
<script>
import EditKssCalendarComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssCalendarComponent.vue';
import PopUpConfirmationComponent from '@/components/global/PopUpConfirmationComponent.vue';

export default {
  name: 'PersonalGoalsComponent',
  props: {
    data: Object,
  },
  components: {
    EditKssCalendarComponent,
    PopUpConfirmationComponent,
  },
  data() {
    return {
      step: 1,
      showCalendar: false,
      selectedDate: new Date(),
      reconfirmation: false,
      showPopup: false,
      popupMessage: this.$t('PersonalGoals.popupMessage'),
      continueButtonText: this.$t('PersonalGoals.continue'),
      cancelButtonText: this.$t('PersonalGoals.cancel'),
      goalName: '',
      goalLength: '',
      specific: '',
      measurable: '',
      achievable: '',
      relevant: '',
      goalDate: '',
      goalType: this.$t('PersonalGoals.goalType.habit'),
      goalNameChanged: false,
      disableNextButton: (this.$store.getters.getActiveGoalsCount
        >= this.$store.getters.getPersonalGoalsMaxLimit),
      time: '12:00',
      // hour: '12',
      recurringDays: this.$store.getters.getRecurringDays,
    };
  },
  beforeMount() {
    this.getPersonalGoalsLength();
  },
  mounted() {
    this.initDefaultGoalName();
  },
  computed: {
    formattedStartDate() {
      if (this.goalDate === '') {
        return `${(`0${this.selectedDate.getDate()}`).slice(-2)}.${(`0${this.selectedDate.getMonth() + 1}`).slice(-2)}.${this.selectedDate.getFullYear()}`;
      }
      return `${(`0${this.goalDate.getDate()}`).slice(-2)}.${(`0${this.goalDate.getMonth() + 1}`).slice(-2)}.${this.goalDate.getFullYear()}`;
    },
    editable() {
      return true;
    },
    getShowCalendar() {
      return this.showCalendar;
    },
    getDays() {
      return this.$store.getters.getRecurringDays;
    },
  },
  watch: {
    goalName() {
      if (this.goalName !== `Goal ${this.getPersonalGoalsLength() + 1}`) {
        this.goalNameChanged = true;
      }
    },
  },
  methods: {
    initDefaultGoalName() {
      this.goalName = `Goal ${this.getPersonalGoalsLength() + 1}`;
    },
    getPersonalGoalsLength() {
      const personalGoalsArray = this.$store.getters.getPersonalGoals;
      if (!personalGoalsArray) {
        this.goalLength = '0';
        return 0;
      }
      return personalGoalsArray.length;
    },
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    onPopupCancel() {
      this.showPopup = false;
      this.reconfirmation = false;
    },
    onPopupConfirm() {
      this.showPopup = false;
      this.createPersonalGoal();
    },
    async createPersonalGoal() {
      try {
        if (this.checkForErrorsStepTwo()) {
          return;
        }
        if (!this.reconfirmation) {
          if (this.allFilled()) {
            // show modal to ask for reconfirmation
            this.showPopup = true;
            this.reconfirmation = true;
            return;
          }
        }
        const data = {
          goalName: this.goalName,
          goalType: this.goalType,
          goalDate: this.goalDate,
          questionAndAnswer: [
            {
              question: this.$t('PersonalGoals.qSpecific'),
              answer: this.specific,
              answerRequired: false,
              linkToIcon: '',
              questionKeyword: this.$t('PersonalGoals.specific'),
            },
            {
              question: this.$t('PersonalGoals.qMeasurable'),
              answer: this.measurable,
              answerRequired: false,
              linkToIcon: '',
              questionKeyword: this.$t('PersonalGoals.measurable'),
            },
            {
              question: this.$t('PersonalGoals.qAchievable'),
              answer: this.achievable,
              answerRequired: false,
              linkToIcon: '',
              questionKeyword: this.$t('PersonalGoals.achievable'),
            },
            {
              question: this.$t('PersonalGoals.qRelevant'),
              answer: this.relevant,
              answerRequired: false,
              linkToIcon: '',
              questionKeyword: this.$t('PersonalGoals.relevant'),
            },
          ],
          reminderTime: {
            time: this.time,
            days: this.recurringDays,
          },
        };
        await this.$store.dispatch('createPersonalGoal', data);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('PersonalGoals.successMsg'),
          type: 'success',
        });
        this.$store.commit('setActiveGoalsCount', (this.$store.getters.getActiveGoalsCount + 1));
        await this.$store.dispatch('getPersonalGoals');
        this.closePopup();
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    allFilled() {
      return (this.specific.trim() === '' || this.measurable.trim() === '' || this.achievable.trim() === '' || this.relevant.trim() === '');
    },
    checkForErrorsStepOne() {
      let hasError = false;
      if (this.goalName.trim() === '') {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('PersonalGoals.enterName'),
          type: 'error',
        });
        this.goalName = '';
        hasError = true;
      }
      if (this.goalType.trim() === '') {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('PersonalGoals.selectGoal'),
          type: 'error',
        });
        hasError = true;
      }
      if (hasError) {
        return hasError;
      }
      this.step = 2;
      return hasError;
    },
    checkForErrorsStepTwo() {
      let hasError = false;
      const currentDate = new Date();
      if (this.goalDate - currentDate < 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('PersonalGoals.incorrectDate'),
          type: 'error',
        });
        hasError = true;
      }
      if (this.goalType === 'Habit') {
        const len = this.recurringDays.filter((e) => e.isSelected).length;
        if (len === 0) {
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('ToastComponenet.kss.selectRecurringDays'),
            type: 'error',
          });
          hasError = true;
        }
      }

      return hasError;
    },
    toggleShowCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    updateSelectedDate(date) {
      this.goalDate = date;
      this.toggleShowCalendar();
    },
    selectDay(index) {
      this.recurringDays[index].isSelected = !this.recurringDays[index].isSelected;
    },
  },
};
</script>

<style scoped>
.answers {
  font-family: Poppins, sans-serif;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background-color: var(--color-light);
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  height: 3rem;
  margin-right: 50px;
  font-size: 16px;
}

.question-icon {
  font-size: 20px;
  margin-right: 10px;
}

.question-label {
  font-family: Poppins, sans-serif;
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  margin-bottom: 1rem;
}

.question {
  width: 100%;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.time-box {
  display: flex;
  flex-wrap: wrap;
}

.left-side {
  flex: 1;
  margin-left: 1rem;
}

.right-side {
  flex: 1;
}

.spacer {
  flex: 0.1;
}
#div_goals-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.full-width {
  width: 90%;
  display: block;
  box-sizing: border-box;
  margin: 1rem;
}

p {
  padding: 0.5rem 0;
}

.forms .time-wrapper {
  /*min-height: 100vh;*/
  box-sizing: border-box;
  padding: 0px;
  max-width: 960px;
  margin: 0 auto;
}

.forms .time-wrapper .time-box {
  display: flex;
  flex-wrap: wrap;
}

.time-box .label {
  text-align: center;
  display: block;
  width: 350px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: var(--color-orange);
  background-color: var(--color-milk);
  cursor: pointer;
}

.goalNameChanged {
  color: black;
}

.time-box .area {
  /*display: none;*/
  order: 99;
  flex-grow: 1;
  width: 100%;
}
.area {
  height: 100%;
  color: var(--color-dark);
  padding: 5px;
  border-radius: 5px;
}

select {
  background-color: var(--color-light);
  width: 100%;
  height: 3rem;
  font-size: 20px;
  padding: 0 5px;
  border-radius: 5px;
  border: 1px solid var(--color-light-grey);
  color: var(--color-orange);
  cursor: pointer;
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  margin: 2rem 0;
  box-sizing: border-box;
}

.btn-create {
  font-family: Poppins, sans-serif;
  margin-right: 10px;
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-create:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.forms {
  margin-top: 1rem;
  margin-right: -2.5rem;
  margin-left: -2.5rem;
}

.forms .dropdown {
  width: 150px;
  height: 30px;
  display: none;

  margin-top: 30px;
}

.noGoalNameChange {
  color: var(--color-disabled);
}

.goalNameChanged {
  color: var(--color-dark);
}

.runtime {
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--color-milk);
}

.timer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.timer .time {
  font-size: 30px;
  font-weight: 700;
  /* padding: var(--card-padding) 0 0 0;*/
}

.drop_event {
  margin-bottom: 1rem;
}

.seven_days {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 25px;
}

.seven_days label {
  display: flex;
  position: relative;
  margin: 5px;
  font-size: 13px;
  width: fit-content;
}

.seven_days label#mon {
  margin-left: 0;
}

.seven_days label .content-day {
  display: flex;
  padding: 15px;
  width: 100%;
  border: 1px solid var(--color-light-grey);
  border-radius: 10px;
  margin: 0px;
  cursor: pointer;
}

.seven_days span {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.seven_days .checked span {
  font-weight: 600;
}

.seven_days .checked .content-day .icon {
  display: block;
}

.seven_days .content-day .icon {
  position: absolute;
  top: 0px;
  right: 7px;
}

.seven_days .checked {
  color: var(--color-orange);
  background-color: var(--color-bisque);
  border: var(--color-orange);
}

.drop_event {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

label {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 18px;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
}

@media (max-width: 720px) {
  .drop_event {
    flex-direction: column;
    margin-top: 10px;
  }
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  .time-box .label {
    margin-left: auto;
    margin-right: auto;
    width: 130px;
  }

  .time-box .label span {
    display: none;
  }

  .spacer {
    display: none;
  }

  .btns {
    flex-direction: column;
    align-items: flex-end;
  }

  .btn-create {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .time-box .label {
    margin-left: auto;
    margin-right: auto;
    width: 230px;
  }

  .time-box .label span {
    display: block;
  }

  .time-box {
    flex-direction: column;
  }

  .spacer {
    display: none;
  }
}

@media (min-width: 1920px) {
  .time-box .label {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 617px) {
  .btns {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 1149px) {
  .forms {
    margin-left: 0.5rem;
  }

  .question {
    margin-right: 2rem;
  }

  .btn-create {
    margin-right: 2rem;
    margin-bottom: 0.25rem;
  }
}

@media (max-width: 800px) {

  .question {
    width: 95%;
    margin-bottom: 1.5rem;
  }
}
</style>
