<template>
  <div class="popup-feedback">
    <button class="close-btn" @click="closePopup">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
    <div class="feedback-grid">
      <font-awesome-icon class="comments-icon" icon="fa-comments"/>
      <div>
        <p><b>{{ $t('ProvideFeedbackComponent.yourFeedback') }}</b></p>
      </div>
    </div>
    <div class="container">
      <form action="#">
        <div class="subheading-container" >
          <b>
          <label class="subheadings" v-if="this.name">{{ nameLabel }}</label>
          <label class="subheadings" v-if="this.name">{{ name }}</label>
          </b>
        </div>
        <textarea v-model="text" maxlength="5000"
          :placeholder="$t('ProvideFeedbackComponent.placeholderText')"></textarea>
        <div class="overview-item">
          <div class="icon-wrapper" @click.prevent="markAnonymous">
            <font-awesome-icon v-if="!this.anonymous" class="unchecked-icon"
                               icon="fa-regular fa-square"/>
            <font-awesome-icon v-if="this.anonymous" class="checked-icon" icon="fa-square-check"/>
            <label class="overview-title">
              <b>{{ $t('ProvideFeedbackComponent.anonymous') }}</b>
            </label>
          </div>
          <div>
            <p class="overview-subtext">
              <i>{{ $t('ProvideFeedbackComponent.subText') }}</i>
            </p>
          </div>
        </div>
        <div class="feedback-btn-grid">
          <button type="button" class="grid-button-submit" :disabled="buttonDisabled"
                  @click.prevent="submitFeedback">
            {{ $t('ProvideFeedbackComponent.sendFeedback') }}
          </button>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ProvideFeedbackOverlayComponent',
  props: {
    data: Object,
  },
  data() {
    return {
      nameLabel: (this.data.notification.content.firstName === 'external' ? 'Email:' : 'Name:'),
      name: this.data.notification.content.name,
      userId: this.data.notification.content.userId,
      text: '',
      anonymous: true,
    };
  },
  mounted() {
    this.scrollToElement();
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    markAnonymous() {
      this.anonymous = !this.anonymous;
    },
    async submitFeedback() {
      try {
        if (!this.inputCheck()) {
          return;
        }
        this.buttonDisabled = true;
        await this.$store.dispatch('sendUserFeedback', {
          feedbackText: this.text,
          feedbackDate: new Date(),
          pageName: this.$router.currentRoute._rawValue.name,
          userId: (this.anonymous) ? '' : this.userId,
          anonymous: this.anonymous,
          userName: (this.anonymous) ? '-' : this.name,
        });
        this.buttonDisabled = false;
        this.$store.commit('removeFrontendOverlay', this.data.id);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ProvideFeedbackComponent.feedbackSent'),
          type: 'success',
        });
      } catch (error) {
        this.buttonDisabled = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ProvideFeedbackComponent.errorCouldNotFeedback'),
          type: 'error',
        });
      }
    },
    inputCheck() {
      if (this.text.trim() === '') {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ProvideFeedbackComponent.errorRequired'),
          type: 'error',
        });
        return false;
      }
      return true;
    },
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    scrollToElement() {
      const el = this.$refs.devTipEl;
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<style scoped>
.popup-feedback {
    color: white;
    font-size: 14px;
    position: absolute;
    top: 400px;
    transform: translate(-50%, -50%);
    /* Add transform to center the popup */
    margin-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 1em 3em;
    border-radius: var(--card-border-radius);
    line-height: 1.5em;
    scroll-margin-top: var(--feedback-scroll-margin);
}

.popup-feedback .container {
    padding-left: 3em;
}

.popup-feedback .container p {
    padding-left: 0.5rem;
    padding-right: 3rem;
    text-align: justify;
}

.comments-icon {
    font-size: 28px;
    color: var(--color-orange);
    text-shadow: 0 0 10px var(--color-orange);
}

.feedback-btn-grid {
    padding-right: 3.5rem;
}

.feedback-btn-grid button.grid-button-submit {
    cursor: pointer;
    text-decoration: none;
    color: #1B1B1B;
    /*color: white;*/
    background: white none;
    /*background: #F49718 none;*/
    border: 1px solid var(--color-orange);
    /*border: 1px solid #1E2019;*/
    padding: .5em 1.5em .5em 1.5em;
    border-radius: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 140px;
}

.feedback-btn-grid button.grid-button-submit>svg {
    margin-right: 8px;
}

.feedback-btn-grid button.grid-button-submit:hover {
    text-decoration: none;
    color: white;
    /*color: #1B1B1B;*/
    background-color: #F49718;
    /*background-color: white;*/
    border: 1px solid #1E2019;
    /*border: 1px solid var(--color-orange);*/
    padding: .5em 1.5em .5em 1.5em;
    border-radius: 2em;
}

.feedback-btn-grid button.grid-button-submit:focus {
    outline: none;
}

.subheading-container {
    margin: 1.5rem 0.5rem;
    display: flex;
    justify-content: flex-start;
}

.subheadings {
    width: fit-content !important;
    white-space: nowrap;
    margin-right: 1rem;
}

.grid-button-submit:disabled,
.grid-button-submit:disabled:hover,
.grid-button:disabled:hover {
    opacity: 0.4;
    background: var(--color-disabled) !important;
}

.container textarea {
    resize: vertical;
}

.feedback-grid {
    display: grid;
    margin-left: -2em;
    grid-template-columns: 50px 1fr;
    place-items: center;
    justify-items: center;
}

.feedback-grid div {
    text-align: left;
    width: 100%;
    line-height: .7em;
}

.feedback-btn-grid {
    display: grid;
    place-items: center;
    justify-items: right;
}

.feedback-btn-grid .grid-button-submit {
    text-decoration: none;
    color: #1B1B1B;
    /*color: #1B1B1B;*/
    background: white none;
    /*background: #F49718;*/
    border: 1px solid var(--color-orange);
    /*border: 1px solid #1B1B1B;*/
    padding: .5em 1.5em .5em 1.5em;
    border-radius: 2em;
    display: inline-flex;
    align-items: center;
    width: auto;
    max-width: 150px;
}

.popup-feedback form textarea {
    width: 100%;
    height: 6.5em;
    border: none;
    outline: none;
    color: #000;
    font-size: 16px;
    margin-bottom: 1em;
    margin-top: -.5em;
    border-radius: 1em;
    padding: 1em;
}

.popup-feedback form textarea:disabled {
    background-color: rgba(219, 219, 219, 0.9);
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 24px;
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    color: var(--color-milk);
}

.close-btn:hover {
    color: var(--color-orange);
}

.overview-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: .5em;
  margin-top: .5rem;
}

.overview-item .unchecked-icon,
.overview-item .checked-icon {
  margin-right: .5em;
  align-self: center;
}

.icon-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 10;
  cursor: pointer;
  align-items: center;
}

.checked-icon {
  color: var(--color-orange);
  font-size: 20px;
}

.unchecked-icon {
  font-size: 20px;
}

.overview-title {
  margin-bottom: 0.1em;
}

.overview-subtext {
  margin-bottom: 0.1em;
  font-size: 12px;
}

@media only screen and (min-width: 1056px) {
    .popup-feedback {
        font-size: 16px;
        left: 50%;
        width: 60%;
        max-width: 90vh;
    }

    .grid-button-submit {
        width: auto;
    }

    .feedback-btn-grid {
        padding-right: 0.5rem;
    }

    .popup-feedback .container {
        padding-top: 0.5rem;
        padding-left: 0rem;
    }

    .popup-feedback .container p {
        padding-left: 0.5rem;
        padding-right: 2.5rem;
        text-align: justify;
    }
}

@media only screen and (max-width: 1055px) {

    .popup-feedback {
        font-size: 16px;
        left: 50%;
        width: 100%;
        max-width: 90vh;
        padding: 0.5rem 1.5rem 1.5rem 1.5rem;
        position: absolute !important;
    }

    .popup-feedback b {
        line-height: 20px;
    }

    .popup-feedback .container {
        padding-top: 0.5rem;
        padding-left: 0rem;
    }

    .popup-feedback .container p {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        text-align: justify;
    }

    .subheadings {
        width: 100% !important;
    }

    .popup-feedback form textarea {
        width: 98%;
    }

    .feedback-grid {
        margin-left: -1em !important;
    }

    .feedback-btn-grid {
        padding-right: 0.5rem;
    }
}

@media only screen and (max-width: 850px) {
    .popup-feedback {
        font-size: 16px;
        left: 50%;
        width: 80%;
        max-width: 90vh;
        padding: 0.5rem 1.5rem 1.5rem 1.5rem;
        position: absolute !important;
    }
}
</style>
