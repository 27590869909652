<template>
  <div :class="getStyle" @click="showPopup">
    <div>
      <font-awesome-icon icon="fa-circle-info" class="circle-info-icon"/>
    </div>
    <div v-if="displayOptions">
      <div class="option">
          <label @click="displayFeedbackForm">{{ $t('ProvideFeedbackComponent.feedback') }}</label>
      </div>
      <div class="option">
          <label @click="displayDemoOverlay">{{ $t('ProvideFeedbackComponent.demo') }}</label>
      </div>
  </div>
  </div>
</template>
<script>
export default {
  name: 'ProvideFeedbackComponent',
  data() {
    return {
      user: {},
      displayOptions: false,
    };
  },
  computed: {
    getStyle() {
      return this.displayOptions ? 'popup-block' : 'feedback-button';
    },
  },
  methods: {
    async displayFeedbackForm() {
      try {
        await this.$store.dispatch('getUserInfo');
        this.user = this.$store.getters.getUserDetails;
      } catch (e) {
        this.user.userId = this.$store.getters.getUserId;
      } finally {
        this.$store.commit('addFrontendOverlay', {
          id: Math.random(),
          type: 'provideFeedback',
          notification: {
            content: {
              name: this.user.name || '',
              firstName: this.user.firstName || '',
              userId: this.user.userId || '',
            },
          },
        });
        this.showPopup();
      }
    },
    showPopup() {
      this.displayOptions = !this.displayOptions;
    },
    async displayDemoOverlay() {
      await this.$store.dispatch('showNewUserDemo');
      this.showPopup();
    },
  },
};
</script>
<style scoped>
.feedback-button {
  position: fixed;
  width: fit-content;
  height: 54px;
  padding: 1rem;
  bottom: 65px;
  right: 35px;
  color: var(--color-orange);
  background-color: var(--color-white);
  border: 1px solid;
  border-radius: 28px;
  border-color: var(--color-orange);
  z-index: 5 !important;
  display: flex;
}

.feedback-button:hover {
    box-shadow: 0 0 3px 1px var(--color-orange);
    cursor: pointer;
}

label {
    padding-left: 5px;
    cursor: pointer;
    position: relative;
    font-size: 18px;
    font-weight: 400;
    height: 50%;
    font-family: "Poppins", sans-serif;
}

label:hover {
    color: var(--color-orange);
}

.circle-info-icon {
    vertical-align: 0.125em;
    height: 1.5em;
}

.popup-block {
  bottom: 65px;
  right: 20px;
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
  width: calc(100% - 40px);
  max-width: 230px;
  min-width: 190px;
  height: 100px;
  border-radius: 24px;
  font-family: Arial, sans-serif;
  flex: 1;
  margin-right:15px;
  padding-top: 10px;
  border: 1px solid;
  border-color: var(--color-orange);
  color: var(--color-orange);
  background-color: var(--color-white);
}

.option {
  display: flex;
  height: fit-content;
  background-color: var(--color-milk);
  color: black;
  font-family: Arial, sans-serif;
  flex: 1;
  margin-left: 10px;
  margin-bottom: 15px;
}

.popup-block .circle-info-icon {
    margin-right: 16px;
    margin-bottom: 6px;
}

@media (max-width: 768px) {
  .popup-block {
    z-index: 1001 !important;
  }
}
</style>
