<template>
  <div :class="mindsetCardClass">
    <div @click.prevent="toggleDetails" class="parent-div">
      <div class="headline">
        <div class="task-info">
          <h2> 360° OMindset </h2>
        </div>
        <div class="delete">
          <i v-if="editable !== false">
            <font-awesome-icon icon="fa-pen"/>
          </i>
        </div>
      </div>
      <div class="participants-status" v-if="usersCount > 0">
        <div class="participant-icon-count">
          <i class="icon">
            <font-awesome-icon icon="fa-users"/>
          </i>
          <p class="participants-label">{{
              usersCount + ' ' + $tc('Mindset360Display.participants', usersCount)
            }} |</p>
        </div>
        <div>
          <p class="participants-percent">{{ participantsPercent }}%</p>
        </div>
      </div>
      <div class="status">
        <div class="time-rubber" :style="isActive">
          <i class="icon">
            <font-awesome-icon icon="fa-calendar"/>
          </i>
          <p class="labelname">{{ formattedDate }}</p>
        </div>
      </div>
    </div>
    <div v-if="showContainer" class="expandable">
      <div class="content">
        <div class="forms">
          <div class="participants">
            <mindset360-participants-component v-for="participant in involvedUsers"
                                               :participant="participant" :mindsetStatus="status"
                                               :key="participant._id"/>
          </div>
          <!-- TODO -->
          <!-- Test Edge Cases -->
          <div class="btns" v-if="participantsPercent !== 100 && this.status">
            <button @mouseover="canShowExtensionMessage"
                    @focus="canShowExtensionMessage"
                    @mouseout="canShowExtensionMessage"
                    @blur="canShowExtensionMessage"
                    type="button"
                    class="btn-extend button-relative" name="extend"
                    id="btn_extend" :disabled="enableExtension" @click="extendDuration">
              {{ $t('Mindset360Display.extendDuration') }}
              <div v-show="this.showExtensionMessage" class="button-disabled-message">
                    <span id="txt_dataMissing">
                      {{ $t('Mindset360Display.3DayMessage') }}
                    </span>
              </div>
            </button>
            <button @mouseover="toggleReminderMessage"
                    @focus="toggleReminderMessage"
                    @mouseout="toggleReminderMessage"
                    @blur="toggleReminderMessage"
                    type="button" class="btn-create button-relative" name="reminder"
                    id="btn_reminder" @click="sendReminder"
                    :disabled="!this.isLastReminderDateSevenDaysOrMore">
              {{ $t('Mindset360Display.sendReminder') }}
              <div v-show="this.showReminderMessage" class="button-disabled-message">
                    <span id="txt_dataMissing">
                      {{ $t('Mindset360Display.7DayMessage') }}
                    </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mindset360ParticipantsComponent
  from '@/components/Home/Mindset360Creator/Mindset360ParticipantsComponent.vue';

export default {
  name: 'Mindset360CardComponent',
  props: ['mindset'],
  components: {
    Mindset360ParticipantsComponent,
  },
  data() {
    return {
      showExtensionMessage: false,
      showReminderMessage: false,
      status: (this.mindset.status === 'active'),
      showContainer: false,
      usersCount: this.mindset.involvedUsers.length || 0,
      selectedDate: new Date(this.mindset.endDate),
      editable: true,
    };
  },
  computed: {
    isLastReminderDateSevenDaysOrMore() {
      const currentDate = new Date();
      const lastReminderDate = new Date(this.mindset.taskEvents[0].date_lastReminderSent);
      const differenceInTime = Math.abs(lastReminderDate - currentDate);
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
      if (lastReminderDate instanceof Date) {
        if (differenceInDays >= 7) {
          return true;
        }
      }
      return false;
    },
    mindsetCardClass() {
      return this.status ? 'mindset-card-active' : 'mindset-card-inactive';
    },
    participantsPercent() {
      const percent = this.mindset.percent.toFixed(2);
      return Number(percent);
    },
    formattedDate() {
      if (!this.selectedDate) {
        return '';
      }
      return `${(`0${this.selectedDate.getDate()}`).slice(-2)}.${(`0${this.selectedDate.getMonth() + 1}`).slice(-2)}.${this.selectedDate.getFullYear()}`;
    },
    involvedUsers() {
      return this.mindset.userDetails;
    },
    enableExtension() {
      return !this.mindset.extendDuration;
    },
    isActive() {
      return this.status ? 'background-color: var(--color-orange);' : 'background-color: var(--color-disabled);';
    },
  },
  methods: {
    canShowExtensionMessage() {
      if (this.enableExtension) {
        this.showExtensionMessage = !this.showExtensionMessage;
      }
    },
    toggleReminderMessage() {
      if (!this.isLastReminderDateSevenDaysOrMore) {
        this.showReminderMessage = !this.showReminderMessage;
      }
    },
    toggleDetails() {
      this.showContainer = !this.showContainer;
    },
    async sendReminder() {
      const data = {
        taskID: this.mindset._id,
        taskEventId: this.mindset.taskEvents[this.mindset.taskEvents.length - 1]._id,
      };
      await this.$store.dispatch('send360MindsetReminder', data);
      this.$store.commit('setToastNotification', {
        id: Math.random(),
        message: this.$t('Mindset360Display.reminderNotification'),
        type: 'success',
      });
      await this.$store.dispatch('get360Mindset');
    },
    async extendDuration() {
      const data = {
        taskID: this.mindset._id,
      };
      await this.$store.dispatch('extend360Mindset', data);
      this.$store.commit('setToastNotification', {
        id: Math.random(),
        message: this.$t('Mindset360Display.extensionNotification'),
        type: 'success',
      });
      await this.$store.dispatch('get360Mindset');
    },
  },
};
</script>
<style scoped>
.mindset-card-active {
  position: relative;
  width: 100%;
  max-width: 1440px;
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  scroll-margin-top: 50px;
}

.mindset-card-inactive {
  background: var(--color-light-grey);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  scroll-margin-top: 50px;
}

.mindset-card-active:hover {
  box-shadow: 0 4px 20px 0 #aaa;
  cursor: pointer;
}

.headline, .status {
  display: flex;
  justify-content: space-between;
}

h2 {
  font-size: 23px;
}

.button-relative {
  position: relative;
}

.button-disabled-message {
  font-size: 14px;
  top: -60px;
  left: 0;
  z-index: 10000;
  position: absolute;
  padding: 5px;
  color: white;
  border-radius: 10px;
  background-color: var(--color-error);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  user-select: none;
  text-align: center;
  transition: opacity 0.3s, transform 0.3s;
  width: 100%;
}

.button-disabled-message::before {
  z-index: 10000; /* Set a high z-index for the triangle as well */
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background-color: var(--color-error);
  bottom: -8px;
  left: 25%;
  transform: translateX(-50%) rotate(45deg);
}

.headline .delete i {
  padding-left: 10px;
  font-size: 1.3rem;
  color: var(--color-disabled);
}

.headline .delete i:hover {
  color: var(--color-orange);
}

.participants-status {
  height: 2.2rem;
  margin: 0.2rem 0.2rem 0.2rem 0rem;
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.participants-label {
  margin-left: 0.6rem;
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
}

.participants-percent {
  margin-left: 0.6rem;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  color: var(--color-orange)
}

.time-rubber {
  display: flex;
  flex-direction: row;
  margin: 0.2rem 0;
  padding: 0.7rem;
  align-items: center;
  height: 2.2rem;
  width: min-content;
  border-radius: 4px;
}

.time-rubber .icon {
  display: flex;
  padding: 3px;
  width: 2rem;
  color: var(--color-white);
}

.labelname {
  color: var(--color-white);
  padding: 3px;
}

.expandable {
  z-index: 20;
  cursor: default;
}

.forms {
  margin-top: 20px;
  background-color: var(--color-milk);
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px;
}

.participants {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btns {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 2rem;
  box-sizing: border-box;
}

.btn-create, .btn-extend {
  font-family: Poppins, sans-serif;
  margin-right: 10px;
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.btn-create {
  background-color: var(--color-bisque);
}

.btn-extend {
  background-color: var(--color-light-grey);
}

.btn-create:hover {
  transition: .5s;
  background-color: var(--color-orange);
}

.btn-extend:hover {
  transition: .5s;
  background-color: var(--color-disabled);
}

.parent-div {
  display: flex;
  flex-direction: column;
}

.participant-icon-count {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-extend:disabled {
  color: rgb(0, 0, 0, .5);
  background-color: rgb(231, 231, 231, 0.5);/*Color Light Grey RGB to avoid changing child opac */
  cursor: not-allowed;
}

.btn-extend:hover:disabled {
  transition: .5s;
  color: rgb(0, 0, 0, .5);
  background-color: rgb(231, 231, 231, 0.5);/*Color Light Grey RGB to avoid changing child opac */
}

.btn-create:disabled {
  color: rgb(0, 0, 0, .5);
  background-color: rgb(255, 228, 196, 0.5);/*Color Bisque RGB to avoid changing child opacity*/
  cursor: not-allowed;
}
.btn-create:hover:disabled {
  transition: .5s;
  background-color: rgb(255, 228, 196, 0.5);/*Color Bisque RGB to avoid effecting child opacity*/
  color: rgb(0, 0, 0, .5);
}

@media (max-width: 612px) {
  .btns {
    flex-direction: column;
    align-items: flex-end;
  }

  .btn-extend {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .btn-create {
    margin-right: 0;
  }
}

@media (max-width: 360px) {
  .participants-status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

</style>
