<template>
  <div class="selection">
    <div class="selection-from">
      <div class="selection-text">
        <p>{{ $t('TaskCreatorOverlayComponent.chooseEvent') }}</p>
        <div class="list-items" @click.prevent="toggleShowDropdown">
          <div class="item checked" id="yourself_left">
                        <span class="item-text" id="txt_yourself">
                            {{ truncateText(selectedOption.name, 20) }} -
                            {{ formatDate(selectedOption.date_assessmentMade) }}
                        </span>
            <span class="checkbox">
                            <i class="fa-solid fa-circle-check check-icon">
                                <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                            </i>
                        </span>
          </div>
        </div>
      </div>
      <div class="selection-container" v-if="showDropDown">
        <input type="text" class="search-input" v-model="searchText">

        <ul class="list-items">
          <div v-if="filteredOptions.length">
            <li v-for="option in filteredOptions" :key="option._id" :class="[
                            'item',
                            { 'checked': selectedOption === option }]"
                @click.prevent="setSelected(option)">
                            <span>
                                {{ truncateText(option.name, 20) }} -
                                {{ formatDate(option.date_assessmentMade) }}
                            </span>
              <span class="checkbox">
                                <i class="fa-solid fa-circle-check check-icon">
                                    <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                                </i>
                            </span>
            </li>
          </div>
          <div v-else>
            {{ $t('SearchDropdownComponent.notEnoughDataMatchingSearch') }}
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchDropdownComponent',
  data() {
    return {
      showDropDown: false,
      searchText: '',
    };
  },
  computed: {
    selectedOption() {
      return this.$store.getters.getSelectedKssEvalutionPerMeeting;
    },
    options() {
      return this.$store.getters.getKssEvalutionPerMeeting;
    },
    filteredOptions() {
      return this.options.filter(
        (option) => option.name.toLowerCase().includes(this.searchText.toLowerCase()),
      );
    },
  },
  methods: {
    async setSelectedKssEvalutionPerMeeting(selectedAssesment) {
      try {
        await this.$store.dispatch('getKssEvalutionPerMeetingPlotData', selectedAssesment);
      } catch (e) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('SearchDropdownComponent.errorOccured'),
          type: 'error',
        });
      }
    },
    toggleShowDropdown() {
      this.showDropDown = !this.showDropDown;
    },
    async setSelected(selection) {
      this.showDropDown = false;
      await this.setSelectedKssEvalutionPerMeeting(selection);
    },
    formatDate(originalDate) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'CET',
        hour12: false,
      };

      let Locale = 'en';
      if (this.$i18n) {
        Locale = this.$i18n.locale;
      }

      const formattedDate = new Date(originalDate).toLocaleString(Locale, options);
      if (formattedDate === 'Invalid Date') {
        return ' ';
      }
      return formattedDate;
    },
    truncateText(text, maxLength) {
      if (text) {
        if (text.length > maxLength) {
          return `${text.slice(0, maxLength)}...`;
        }
      }
      return text;
    },
  },
};
</script>

<style scoped>
.selection {
  position: relative;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
}

.selection-text {
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  margin-right: 10px;
  font-family: "Poppins";
}

.selection-text p {
  margin-right: 10px;
}

.selection-from {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 300px;
  margin-right: 50px;
  position: relative;
}

.selection-container {
  position: absolute;
  top: 70px;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  padding-bottom: 8px;
  z-index: 2;

  padding: 0.5rem 1rem;
  border: 1px solid var(--color-light-grey);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
}

.selection-from .list-items {
  display: block;
  max-height: 250px;
  min-width: 250px;
  overflow-y: auto;
  position: relative;
}

.selection-from .list-items::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 0 8px 8px 0;
}

.selection-from .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.list-items {
  width: 100%;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;
  border: 1px solid var(--color-light-grey);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
  overflow: hidden;
  z-index: 5;
  position: relative;
}

.list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 5px;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-left: 5px;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 24px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.item.checked .check-icon {
  transform: scale(1);
}

.search-input {
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 14px;
  padding: 0.25rem 0.75rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--color-light-grey);
}

@media only screen and (min-width: 1150px) {
  .creator {
    padding: 0 50px;
  }
}

@media screen and (max-width: 800px) {
  h1 {
    text-align: center;
    margin: 30px 0 10px 0;
  }

  .selection {
    justify-content: center;
    position: relative;
  }

  .selection-text {
    flex-direction: column;
    align-items: center;
    white-space: normal;
  }

  .selection-text p {
    margin-bottom: 10px;
  }

  .selection-from {
    margin: 0 auto;
  }

  .selection-container {
    margin: 0 auto;
    top: 110px;
  }
}
</style>
