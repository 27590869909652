<template>
  <div class="container">
    <div class="forms">
      <div class="pass">
        <router-link to="/auth" class="link">
          <i>
            <font-awesome-icon icon="fa-arrow-left"/>
          </i>
          {{ $t('NewPasswordComponent.backToLogin') }}
        </router-link>
      </div>
      <img class="platform-logo" src="@/assets/logo_transparent.png" alt="txt">
      <h1>{{ $t('NewPasswordComponent.headingEnterNewPassword') }}</h1>
      <vee-form :validation-schema="schema" @submit="setNewPassword" v-slot="{ errors }">
        <div class="input-field">
          <vee-field :type="toggleInput" name="newPassword" id="txt_password"
                     :placeholder="$t('NewPasswordComponent.placeholders.enterNewPassword')"
                     :class="{inputError : !!errors.newPassword}"/>
          <i class="togglePassword" @click.prevent="showPassword = !showPassword">
            <font-awesome-icon :icon="passwordIconToDisplay"
                               id="icn_toggle"/>
          </i>
        </div>
        <error-message class="error-message" name="newPassword"></error-message>
        <div class="input-field">
          <vee-field type="password" name="repeatNewPassword" id="txt_repeat_password"
                     :placeholder="$t('NewPasswordComponent.placeholders.repeatNewPassword')"
                     :class="{inputError : !!errors.repeatNewPassword}"/>
        </div>
        <error-message class="error-message" name="repeatNewPassword"></error-message>
        <language-component class="lang-component"/>
        <button type="submit" name="submit" id="btn_changePassword" :disabled="isSubmitting">
          {{ $t('NewPasswordComponent.changePassword') }}
        </button>
      </vee-form>
    </div>
  </div>
</template>

<script>
import LanguageComponent from '@/components/global/LanguageComponent.vue';

export default {
  name: 'NewPasswordComponent',
  props: ['email', 'validationKey'],
  components: { LanguageComponent },
  data() {
    return {
      schema: {
        newPassword: 'required|min:8|max:100',
        repeatNewPassword: 'confirmed:@newPassword',
      },
      isSubmitting: false,
      showPassword: false,
    };
  },
  computed: {
    toggleInput() {
      return this.showPassword ? 'text' : 'password';
    },
    passwordIconToDisplay() {
      return this.showPassword ? 'fa-eye-slash' : 'fa-eye';
    },
  },
  methods: {
    async setNewPassword(values) {
      try {
        this.isSubmitting = true;
        const data = {
          email: this.email,
          password: values.newPassword,
          validationKey: this.validationKey,
        };
        await this.$store.dispatch('setNewPassword', data);
        this.isSubmitting = false;
        this.$store.commit('setNewPasswordSetSuccesful', true);
        this.$router.push('/auth/new-password-success');
      } catch (error) {
        const errorMessage = error.response.data.message;
        const errorCode = error.response.data.code;
        let toastErrorMessage;
        switch (errorCode) {
          case 40008:
            toastErrorMessage = this.$t(
              'PasswordResetComponent.toast.invalidResetPasswordInformation',
            );
            break;
          case 40004:
            toastErrorMessage = this.$t(
              'PasswordResetComponent.toast.userNotFound',
              { param_name: 'E-Mail', param_email: values.email },
            );
            break;
          default:
            toastErrorMessage = errorMessage;
        }
        this.isSubmitting = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: toastErrorMessage,
          type: 'error',
        });
      }
    },
  },
  mounted() {
    if (!this.validationKey || !this.email) {
      this.$router.push('not-found');
    }
  },
};
</script>

<style scoped>
.lang-component {
  float: left;
  margin-top: 5px;
  position: absolute;
}

/*------------ GENERAL ----------------*/

h1 {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
  padding: 0 0 20px 0;
}

p {
  text-align: center;
  margin: 2rem 0;
  padding: 0 10px;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

/*------------ FORM ----------------*/
.container .forms {
  padding: 30px;
}

.error-message {
  margin-top: 2px;
}

.platform-logo {
  display: block;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 10rem;
  height: auto;
}

.forms .input-field {
  position: relative;
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #E7E7E7;

}

.input-field input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: 16px;
}

.input-field .inputError {
  border: 2px solid var(--color-error);
}

.pass {
  float: left;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 600;
  padding: 15px 10px;
}

.pass:hover {
  color: var(--color-fulvous);

}

.pass i {
  padding: 0 .5rem;
}

.input-field i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.input-field i.togglePassword {
  right: 10px;
  color: var(--color-disabled);
}

/*------------------ BUTTONS ------------------*/
button[type=submit] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=submit]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

button[type=submit]:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

/*==================== MEDIA QUERIES =====================*/
@media screen and (max-width: 500px) {
  .container {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}

</style>
