<template>
    <div class="response-container">
      <div class="item-container">
        <div class="heading-container">
          <div class="absolute-chevron">
            <font-awesome-icon
              v-if="this.expanded"
              @click.prevent="this.expanded = !this.expanded"
              class="chevron-icon-down cursor-pointer"
              icon="fa-chevron-up"
            />
            <font-awesome-icon v-else class="chevron-icon-down hidden" icon="fa-chevron-down"
              @click.prevent="this.expanded = !this.expanded" />
          </div>
          <div class="dimensionName">
            <label>{{ dimensionName }}</label>
          </div>
          <div class="reflexion-date">
            <p>
              <font-awesome-icon class="calendar-icon" icon="fa-calendar" />
              {{ formattedDate }}
            </p>
          </div>
        </div>
        <div v-if="this.expanded" class="child-container">
          <div>
            <div class="section-heading">
              <label><b>{{ $t('ReflexionGuideline.yourResponse') }}</b></label>
            </div>
            <template v-if="assessment.textFieldQuestion.length > 0">
              <ReflexionGuidelineTextFieldResponse
                v-for="(question, index) in assessment.textFieldQuestion"
                :key="index"
                :question="question">
              </ReflexionGuidelineTextFieldResponse>
            </template>
          </div>
          <div class="section-heading">
            <label><b>{{ $t('ReflexionGuideline.mindsetComparison') }}</b></label>
          </div>
          <label>{{ rangeSliderQuestion }}</label>
          <div class="rangeScore">
            <div :class="isReflectedInDe">
              <label>{{ $t('ReflexionGuideline.weekResult') }}</label>
              <label class="score reflexion">{{ assessment.rangeSliderQuestion.answer }}</label>
            </div>
            <div :class="isMindsetInDe">
              <label class="score mindset">{{ assessment.mindsetResult }}</label>
              <label>{{ $t('ReflexionGuideline.currentMindset') }}</label>
            </div>
          </div>
          <div class="tip section-heading">
            <div class="quick-tip">
              <font-awesome-icon class="light-bulb" icon="fa-regular fa-lightbulb"/>
              <label><b>{{ $t('ReflexionGuideline.quickTip') }}</b></label>
            </div>
            <div>
              <label>{{ tip }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import ReflexionGuidelineTextFieldResponse from '@/components/Home/ReflexionGuidelineComponent/ReflexionGuidelineTextFieldResponse.vue';

export default {
  name: 'ReflexionGuidelineResponseComponent',
  props: ['assessment'],
  components: { ReflexionGuidelineTextFieldResponse },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    formattedDate() {
      const date = new Date(this.assessment.date_assessmentMade);
      return `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(-2)}.${date.getFullYear()}`;
    },
    tip() {
      if (this.$i18n.locale === 'de') {
        return this.assessment.tip.de;
      }
      return this.assessment.tip.en;
    },
    rangeSliderQuestion() {
      if (this.$i18n.locale === 'de') {
        return this.assessment.rangeSliderQuestion.question_de;
      }
      return this.assessment.rangeSliderQuestion.question_en;
    },
    dimensionName() {
      if (this.$i18n.locale === 'de') {
        return this.assessment.dimensionName.de;
      }
      return this.assessment.dimensionName.en;
    },
    isReflectedInDe() {
      if (this.$i18n.locale === 'de') {
        return 'score-section-reflected-de';
      }
      return 'score-section-reflected';
    },
    isMindsetInDe() {
      if (this.$i18n.locale === 'de') {
        return 'score-section-mindset-de';
      }
      return 'score-section-mindset';
    },
  },
};
</script>
<style scoped>
.response-container {
    max-width: 1440px;
    background-color: rgb(255, 255, 255, 0.6);
    border-radius: var(--card-border-radius);
    padding: var(--card-padding);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin: 20px 10px
}

.item-container {
    display: flex;
    flex-direction: column;
}

.heading-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reflexion-date {
    color: var(--color-orange);
    font-weight: 500;
    padding: 0;
}

.absolute-chevron {
    position: absolute;
    max-height: min-content;
    margin-right: 15px;
}

.chevron-icon-down {
    align-self: flex-start;
    transition: 100ms ease-in;
}

.chevron-icon-up {
    transform: rotate(180deg);
}

.cursor-pointer {
    cursor: pointer;
}

.rangeScore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 1rem;
}

.score {
  display: flex;
  height: 25px;
  width: 60px;
  border-radius: 18px;
  align-content: center;
  margin: 0 15px;
  justify-content: center;
}

.score-section-reflected {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    justify-content: flex-end;
}

.score-section-mindset {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
}

.score-section-reflected-de {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 57%;
}

.score-section-mindset-de {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 43%;
}

.reflexion {
    background-color: #bbb;
}

.mindset {
    background-color: var(--color-orange);
}

.tip {
  background-color: rgba(255, 194, 102, 0.7);
  border-radius: 7px;
  padding: 10px 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  color: var(--color-black);
}

.dimensionName {
    margin: 0 2rem;
    color: var(--color-dark);
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
}

.child-container {
    margin: 0 1rem;
}

.section-heading {
  margin-top: 1.5rem;
}

.quick-tip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.light-bulb {
    height: 30px;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .chevron-icon-down {
        margin: 3px 0 0 0;
    }
}

@media (max-width: 635px) {
  .score-section-mindset {
    justify-content: space-between;
    text-align: right;
  }
}

@media (max-width: 550px) {
  .reflexion-date {
    width: 40%;
  }

  .heading-container {
    align-content: space-between !important;
  }

  .rangeScore {
    flex-direction: column !important;
    margin: 1rem 0 !important;
  }

  .score-section-mindset {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  .score-section-reflected {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 342px) {
  .score-section-mindset {
    text-align: left;
  }

}
</style>
