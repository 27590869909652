<template>
  <div class="workshop-response-container">
    <div class="item-container">
      <div class="heading-container">
        <div class="group-container">
          <div class="toggle-container">
            <div class="absolute-chevron">
            <font-awesome-icon
              v-if="!isSentReflection"
              @click.stop="toggleQuestionContainer"
              :class="{ 'chevron-icon-up': showQuestions }"
              class="chevron-icon-down cursor-pointer"
              icon="fa-chevron-down"
            />
            <font-awesome-icon v-else class="chevron-icon-down hidden" icon="fa-chevron-down" />
            </div>
            <p id="title"
               :class="{'title-margin-left' : !isSentReflection}">{{ workshop.workshopTitle }} </p>
          </div>
        </div>
        <div class="heading-container-facilitator">
          <div id="icons-container">
            <font-awesome-icon
              v-if="workshop.permissionToAction"
              @click="toggleEditWorkshop"
              class="cursor-pointer edit-icons"
              icon="fa-pen "
            />
            <font-awesome-icon
              v-if="workshop.permissionToAction"
              @click="deleteWorkshop"
              class="cursor-pointer edit-icons"
              icon="fa-trash-can"
            />
          </div>
          <div class="flex-right-row">
            <div class="date-name">
              <p id="workshop-date">
                <font-awesome-icon class="calendar-icon" icon="fa-calendar" />
                {{ formattedDate }}
              </p>
              <p>
                {{ workshop.workshopFacilitator?.name }}
              </p>
            </div>
            <template v-if="workshop.permissionToAction">
            <span
              v-if="isSentReflection"
              @click.stop="toggleShowReflectionParticipants"
              class="cursor-pointer"
            >
              {{ $t("WorkshopComponent.SendReflection") }}
            </span>
              <span v-else class="disabled"> {{ $t("WorkshopComponent.SendReflection") }} </span>
            </template>
          </div>
        </div>
      </div>
      <div :class="{ showQuestions: showQuestions }" class="all-questions-container">
        <template v-if="questions.length > 0">
          <workshop-question-component
            v-for="(question, index) in questions"
            :key="index"
            :question="question"
          >
          </workshop-question-component>
        </template>
        <template v-else>
          <p class="notice-question">{{ $t("WorkshopComponent.dataUpdating") }}</p>
        </template>
      </div>
    </div>
    <workshop-form-component @submit="handleSubmitEdit" :value="workshopEditData" v-if="showEdit" />
  </div>
  <pop-up-confirmation-component
    v-if="showPopup"
    :message="popupMessage"
    :confirmButtonText="deleteButtonText"
    :cancelButtonText="cancelButtonText"
    @cancel="onPopupCancel"
    @confirm="onPopupConfirm"
  />
</template>
<script>
import WorkshopQuestionComponent from '@/components/Home/WorkshopComponent/QuestionComponent.vue';
import WorkshopFormComponent from './WorkshopFormComponent.vue';
import PopUpConfirmationComponent from '@/components/global/PopUpConfirmationComponent.vue';
import NetworkWorkshop from '@/helpers/networking/NetworkWorkshop';

export default {
  name: 'WorkshopResponseComponent',
  props: {
    workshop: Object,
  },
  components: { WorkshopQuestionComponent, PopUpConfirmationComponent, WorkshopFormComponent },
  data() {
    return {
      showQuestions: false,
      questions: [],
      questionCount: [1, 2, 3, 4],
      showParticipants: false,
      showPopup: false,
      showEdit: false,
      popupMessage: this.$t('ToastComponent.workshop.workshopDeletedConfirm'),
      deleteButtonText: this.$t('KssTaskCreatedComponent.popup.deleteButton'),
      cancelButtonText: this.$t('KssTaskCreatedComponent.popup.cancelButton'),
    };
  },
  methods: {
    toggleEditWorkshop() {
      this.showEdit = !this.showEdit;
      this.showQuestions = false;
    },
    deleteWorkshop() {
      this.showPopup = true;
    },
    onPopupCancel() {
      this.showPopup = false;
    },
    async handleSubmitEdit(params) {
      try {
        this.$store.dispatch('updateWorkshop', params);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.workshop.workshopUpdated'),
          type: 'success',
        });
        this.toggleEditWorkshop();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    async onPopupConfirm() {
      try {
        this.$store.dispatch('deleteWorkshop', this.workshop._id);
        this.showPopup = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.workshop.workshopDeleted'),
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.workshop.workshopDeletedError'),
          type: 'error',
        });
      }
    },
    async toggleQuestionContainer() {
      this.showQuestions = !this.showQuestions;
      this.showEdit = false;
      if (this.showQuestions === true) {
        try {
          const { data } = await NetworkWorkshop.getQuestionAnswers({
            taskId: this.workshop.relatedTaskId,
          });
          this.questions = data.questions;
        } catch (error) {
          this.questions = [];
        }
      }
    },
    toggleShowReflectionParticipants() {
      this.showParticipants = !this.showParticipants;
      try {
        this.$store.commit('setSelectedWorkshop', this.workshop);
        this.$store.commit('addFrontendOverlay', {
          id: Math.random(),
          type: 'workshopParticipants',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'error',
          type: 'error',
        });
      }
    },
  },
  computed: {
    workshopEditData() {
      return {
        ...this.workshop,
        dateWorkshopBegin: this.workshop.dateWorkshopBegin
          ? new Date(this.workshop.dateWorkshopBegin)
          : new Date(),
        dateWorkshopEnd: this.workshop.dateWorkshopEnd
          ? new Date(this.workshop.dateWorkshopEnd)
          : new Date(),
      };
    },
    formattedDate() {
      const date = new Date(this.workshop.createdAt);
      return `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
        -2,
      )}.${date.getFullYear()}`;
    },
    isSentReflection() {
      return !this.workshop.sentReflexion;
    },
    getPermissions() {
      return this.$store.state.auth.user.permissionsFlags;
    },
  },
};
</script>
<style scoped>
.workshop-response-container {
  max-width: 1440px;
  height: min-content;
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 20px 10px;
}

.item-container {
  display: flex;
  flex-direction: column;
  max-height: min-content;
}

#icons-container {
  display: flex;
}

.heading-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  width: 100%;
}

.absolute-chevron {
  position: absolute;
  max-height: min-content;
  top: 8px;
}

.toggle-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.all-questions-container {
  display: none;
  padding: 10px 10px 0 0;
}

.showQuestions {
  display: flex;
  flex-direction: column;
}

.chevron-icon-down {
  align-self: flex-start;
  transition: 100ms ease-in;
}

.chevron-icon-up {
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer;
}

.calendar-icon {
  padding: 0;
}
.flex-right-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.date-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.edit-icons {
  color: var(--color-disabled);
  margin-left: 15px;
}

.edit-icons:hover {
  color: var(--color-orange);
  transition: 50ms ease-in;
  transform: scale(1.05);
}

#workshop-date {
  color: var(--color-orange);
  padding: 2px 4px;
  white-space: nowrap;
}

.title-margin-left {
  margin-left: 20px;
  word-break: break-all;
}

.heading-container-facilitator {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: auto;
  padding: 0 10px;
}

.heading-container-facilitator div{
  margin-right: 0;
}

.heading-container-facilitator span {
  transition: 100ms ease-in;
  background-color: var(--color-orange);
  padding: 1px 10px;
  border-radius: 25px;
  font-size: 14px;
  color: white;
  white-space: nowrap;
}

.heading-container-facilitator > .disabled {
  background-color: var(--color-disabled);
}

.heading-container-facilitator span:hover {
  filter: brightness(0.93);
}
.heading-container-facilitator > .disabled:hover {
  filter: brightness(1);
}
.heading-container-facilitator > * {
  margin-right: 15px;
}
.hidden {
  display: none;
}
.group-container {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 110px;
  max-width: 100%;
  word-break: break-word;
}
.notice-question {
  display: inline;
  text-align: center;
  color: #999;
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 768px) {
  .group-container {
    margin-right: 0;
    gap: 0px;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    align-items: start;
    max-width: 75%;
  }

  .flex-right-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    min-width: 100%;
  }
  .date-name {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  .calendar-icon {
    margin-left: 0;
  }

  .absolute-chevron {
    position: absolute;
    max-height: min-content;
    top: 0;
  }

  .date-name p {
    margin: 0;
    padding: 0;
  }
  .toggle-container {
    align-items: flex-start;
    margin-left: 0 !important;
  }
  #workshop-date svg {
    margin-left: 0;
  }
  #workshop-date {
    padding-left: 0;
  }

  .group-container div, .heading-container-facilitator div {
    margin-right: 0;
  }

  .heading-container-facilitator div svg {
    margin-left: 10px;
  }

  .heading-container-facilitator {
    flex-direction: column;
    height: auto;
    align-content: space-between;
    justify-content: flex-end;
    align-self: flex-end;
    padding-right: 0;
    padding-left: 0;
    margin: 0;
    min-width: 100% !important;
  }

  .hidden {
    display: none;
  }
  .heading-container {
    align-items: start;
    flex-direction: column;
  }
  .group-container p {
    padding: 0;
  }

  #icons-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  .edit-icons {
   padding: 0 4px;
  }
  .chevron-icon-down {
    margin: 3px 0 0 0;
  }
}

@media (max-width: 521px) {

}
</style>
