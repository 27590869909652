<template>
  <div class="pill-element" :style="status">
    {{ name }}
  </div>
</template>
<script>
export default {
  name: 'Mindset360ParticipantsComponent',
  props: ['participant'],
  data() {
    return {
      name: this.participant.name,
    };
  },
  computed: {
    status() {
      if (this.participant.status === 'submitted') {
        return 'background-color: var(--color-light-grey);';
      }
      return 'background-color: var(--color-orange);';
    },
  },
};
</script>
<style scoped>
.pill-element {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
  color: var(--color-milk);
}

</style>
