<template>
  <breadcrumbs-component :breadCrumbs="teamManagementBreadCrumbs" />
  <div class="content-wrapper">
    <div class="wrapper">
      <!--------- TEAMS --------------->
      <div class="button-container">
        <!----------- CREATE BUTTON --------------->
        <button v-if="getPermissions.admin || getPermissions.companySuperuser"
          class="btn-create-team" id="btn_createTeam" @click.prevent="addNewTeam">
          <i>
            <font-awesome-icon icon="fa-plus" />
          </i>
          {{ $t('TeamAdministrationComponent.createNewTeam') }}
        </button>
      </div>
      <div class="list-of-teams" id="div_teamcontainer">
      <single-edit-team-component v-for="leadedTeam in getLeadedTeams" :key="leadedTeam"
                                :all-users="allUsers"
                                :leaded-team="getTeamWithMappedUsers(leadedTeam)"/>
       </div>
    </div>
  </div>
  <provide-feedback-component></provide-feedback-component>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import SingleEditTeamComponent
  from '@/components/Home/TeamAdministration/SingleEditTeamComponent.vue';
import ProvideFeedbackComponent from '@/components/global/ProvideFeedbackComponent.vue';

export default {
  name: 'TeamAdministrationComponent',
  components: {
    SingleEditTeamComponent,
    BreadcrumbsComponent,
    ProvideFeedbackComponent,
  },
  data() {
    return {
      teamManagementBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/team-administration',
          isActive: false,
          name: this.$t('BreadCrumbs.administration'),
        },
        {
          goTo: '/team-administration',
          isActive: true,
          name: this.$t('BreadCrumbs.teamadministration'),
        },
      ],
      allUsers: this.$store.state.teamManagement.tmAllUsers,
    };
  },
  computed: {
    getLeadedTeams() {
      return this.$store.state.teamManagement.tmLeadedTeams;
    },
    getPermissions() {
      return this.$store.state.auth.user.permissionsFlags;
    },
  },
  methods: {
    getTeamWithMappedUsers(team) {
      const leaders = [];
      const members = [];
      const generalUsers = [];
      team.leaders.forEach((e) => leaders.push(this.mapIdToUser(e)));
      team.members.forEach((e) => members.push(this.mapIdToUser(e)));
      this.allUsers.filter((e) => !team.leaders.includes(e._id))
        .forEach((e) => generalUsers.push({ ...e }));

      return {
        _id: team._id,
        name: team.name,
        leaders,
        members,
        generalUsers,
        displayTeamspace: team.displayTeamspace,
      };
    },
    mapIdToUser(id) {
      let user = null;
      this.allUsers.forEach((e) => {
        if (e._id === id) {
          user = { ...e };
          user.isChecked = false;
        }
      });
      return user;
    },
    addNewTeam() {
      const name = this.$t('SingleEditTeamComponent.newTeamNamePlaceholder');
      this.$store.commit('setNewTeam', name);
    },
  },
  mounted() {
    this.$store.dispatch('getAllUsers');
    this.$store.dispatch('getLeadedTeams');
  },
  unmounted() {
    this.$store.commit('resetTeamManagement');
  },
};
</script>

<style scoped>
/*---------------- GENERAL ------------------*/
.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  max-width: 960px;
  margin: 15px;
}

.content-wrapper {
  width: 960px;

}

.button-container {
  padding: 30px 0;
  position: relative;
}

.btn-create-team {
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-white);
  border: none;
  border-radius: 50px;
  background-color: var(--color-orange);
  cursor: pointer;
}

.btn-create-team:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

/*---------------- TEAM ------------------*/
.list-of-teams {
  width: 100%;
  max-width: 960px;
  margin: 35px 0;
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.btn-create {
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-create:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.btn-save {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.btn-save:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.out {
  display: none;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 15px;
  border: none;
  border-radius: 50px;
  font-size: 15px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.out:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.add-container {
  display: none;
  flex-direction: row;
}

.add-container .in {
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 50px;
  font-size: 15px;
  color: var(--color-white);
  background-color: var(--color-orange);
}

.add-container .in:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

/*------------ FOOTER ---------------*/
footer {
  color: var(--color-white);
  background-color: var(--color-black);
}

.inner-footer {
  margin: 0 auto;
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.inner-footer>* {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 300px;
}

.left {
  padding: 1rem 0;
  text-align: left;
}

.left a {
  color: var(--color-white);
  font-weight: 400;
}

.left a:hover {
  color: var(--color-orange);
}

.left #btn_legal-notice {
  margin-right: 3rem;
}

.right {
  padding: 1rem 0;
  text-align: right;
}

.right a {
  color: var(--color-white);
  font-weight: 400;
}

.right a:hover {
  color: var(--color-orange);
}

/*==================== MEDIA QUERIES =====================*/
@media screen and (max-width: 400px) {
  .single-team .card .headline .edit i {
    padding-left: 3px;
    font-size: 1.2rem;
    color: var(--color-disabled);
  }

  .single-team {
    width: 100%;
    justify-content: center;
    justify-items: center;
  }

  .single-team .toggle-container {
    padding: 20px 0px;
  }

  .single-team .toggle-container .switch-user {
    display: block;
    width: 100%;
    height: fit-content;
  }

  .user-container .team-member,
  .platform-user {
    width: 100%;
  }

  .user-container h3 {
    text-align: center;
    padding: 5px 0px 10px;
    font-size: 15px;
    line-height: 20px;
  }

  .user-container .platform-user h3 {
    margin-top: 30px;
  }

  .user-container.general .list-items {
    max-height: 300px;
  }

  .switch-container {
    display: none;
  }

  .user-container .member {
    padding-top: 10px;
    margin-top: 30px;
  }

  .list-items {
    width: 100%;
  }

  .list-items .item .item-text img {
    width: 2.5rem;
    margin-right: 12px;
  }

  .list-items .item .checkbox {
    height: 18px;
    width: 18px;
    margin-left: 10px;
  }

  .checkbox .check-icon {
    font-size: 22px;
  }

  .out {
    display: block;
    margin-top: 5px;
  }

  .out.tmember {
    margin-top: 5px;
  }

  .add-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .space50px {
    display: none;
  }

  .space150px {
    display: none;
  }
}

@media screen and (min-width: 401px) and (max-width: 520px) {
  .single-team {
    width: 100%;
    justify-content: center;
    justify-items: center;
  }

  .single-team .toggle-container .switch-user {
    display: block;
    width: 100%;
    height: fit-content;
  }

  .user-container .team-member,
  .platform-user {
    width: 100%;
  }

  .user-container h3 {
    text-align: center;
    padding: 5px 0px 10px;
    font-size: 15px;
    line-height: 20px;
  }

  .user-container .platform-user h3 {
    margin-top: 30px;
  }

  .switch-container {
    display: none;
  }

  .user-container .member {
    padding-top: 10px;
    margin-top: 30px;
  }

  .list-items {
    width: 100%;
  }

  .list-items .item .item-text img {
    width: 2.5rem;
    margin-right: 12px;
  }

  .list-items .item .checkbox {
    height: 18px;
    width: 18px;
    margin-left: 10px;
  }

  .checkbox .check-icon {
    font-size: 22px;
  }

  .out {
    display: block;
    margin-top: 5px;
  }

  .out.tmember {
    margin-top: 5px;
  }

  .add-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .space50px {
    display: none;
  }

  .space150px {
    display: none;
  }

  .switch-container {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .content-wrapper {
    width: 100vw;
    padding: 0;
  }
}
</style>
