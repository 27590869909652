<template>
  <breadcrumbs-component :breadCrumbs="breadCrumbs" />
  <div class="mng-dashboard">
    <WeeklyReport />
    <SatisfactionReport v-if="getMoodomatEnabled" />
    <CollaborationTrend v-if="getKSSEnabled" />
    <Omindset />
  </div>
  <ProvideFeedbackComponent></ProvideFeedbackComponent>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import WeeklyReport from './WeeklyReportComponent/WeeklyReportComponent.vue';
import SatisfactionReport from './SatisfactionReportComponent/SatisfactionReportComponent.vue';
import CollaborationTrend from './CollaborationTrendComponent/CollaborationTrendComponent.vue';
import Omindset from './OmindsetComponent/OmindsetComponent.vue';
import ProvideFeedbackComponent from '@/components/global/ProvideFeedbackComponent.vue';

export default {
  name: 'ManagementDashboardComponent',
  components: {
    BreadcrumbsComponent,
    WeeklyReport,
    SatisfactionReport,
    CollaborationTrend,
    Omindset,
    ProvideFeedbackComponent,
  },
  data() {
    return {
      breadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/management-dashboard',
          isActive: true,
          name: this.$t('BreadCrumbs.mngDashboard'),
        },
      ],
    };
  },
  computed: {
    getMoodomatEnabled() {
      return process.env.VUE_APP_MOODOMAT === 'true';
    },
    getKSSEnabled() {
      return process.env.VUE_APP_KSS === 'true';
    },
  },
  methods: {},
};
</script>

<style scoped>
@media (min-width: 1920px) {
  .breadcrumbs {
    margin: 0 5px !important;
  }
}
</style>
