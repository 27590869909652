import axios from 'axios';

export default class NetworkAuth {
  static login(data, config) {
    return axios.post('user/authentication', data, config);
  }

  static refreshToken(data, config) {
    return axios.post('user/authentication/refresh', { refresh_token: data }, config);
  }

  static registerUser(data, config) {
    return axios.post('user', data, config);
  }

  static updateProfile(data, config) {
    return axios.post('user/update', data, config);
  }

  static getTags(config) {
    return axios.get('content/tags/getTags', config);
  }

  static getTagQuestions(config) {
    return axios.get('content/tags/getTagQuestions', config);
  }

  static getMe(config) {
    return axios.get('user/informations', config);
  }

  static getUserInfo(config) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return axios.post('user/info', { timezone }, config);
  }

  static uploadProfilePicture(base64Image, config) {
    return axios.post('user/uploadProfilePicture', { base64Image }, config);
  }

  static validateRegisterData(data, config) {
    return axios.post('user/validateRegistration', data, config);
  }

  static checkIfUserLoggedIn(data, config) {
    return axios.post('ping/token', data, config);
  }

  static requestPasswordReset(data, config) {
    return axios.post('user/reqresetpwd', data, config);
  }

  static setNewPassword(data, config) {
    return axios.post('user/resetpwd', data, config);
  }

  static activateAccount(data, config) {
    return axios.post('user/accountactivation', data, config);
  }

  /**
   * Checks if a token is expired
   * @param token the jwt token
   * @param addSeconds miliseconds to add to the current time
   * @returns {boolean}
   */
  static isTokenExpired(token, addSeconds = 0) {
    try {
      // decode jwt
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

      const payload = JSON.parse(jsonPayload);

      if (!payload || !payload.exp) {
        return true;
      }

      const expirationTime = payload.exp * 1000;
      const addedTime = Date.now() + addSeconds;
      return addedTime > expirationTime;
    } catch (error) {
      return true;
    }
  }
}
