<template>
  <div class="inputs">
    <div class="input-field">
      <vee-field v-model="subjectComputed"
                 :readonly="editable === false"
                 type="text"
                 class="form-control"
                 :class="{'color-disabled': editable === false}"
                 name="subject"
                 id="txt_subject"
                 maxlength="100"
                />
      <label class="form-control inputlabel" id="lbl_mtgSubject">
        {{ $t('KssFormComponent.addMeetingSubject') }}
      </label>
    </div>
    <div class="input-field">
      <vee-field v-model="descriptionComputed"
                 :readonly="editable === false"
                 type="text"
                 class="form-control"
                 :class="{'color-disabled': editable === false}"
                 name="description"
                 id="txt_description"/>
      <label class="form-control inputlabel"
             id="lbl_mtgDescription"> {{ $t('KssFormComponent.addDescription') }}</label>
    </div>
<!----------------- ADD TEAMS ----------------->
    <div class="select-container" v-if="editable !== false">
      <!-- eslint-disable-next-line max-len -->
      <div class="teams-container" ref="teams-container" id="btn_selectTeam" @click.prevent="toggleTeams">
        <div>
           <span class="circle-plus">
                      <font-awesome-icon icon="fa-circle-plus"/>
                    </span>
          <span class="btn-text" id="btn_textTeam">{{ $t('KssFormComponent.addTeams') }}</span>
        </div>
        <div v-if="!showTeams" class="pill-container">
          <div v-for="(selectedTeams) in selectedTeams" :key="selectedTeams"
               class="pill-element">
            {{ selectedTeams.name }}
          </div>
        </div>
      </div>

      <edit-kss-team-selection-component v-if="showTeams"
            :checkedKssTeams="checkedKssTeams"
            :allKssParticipants="allKssParticipants"
            :selectedIds="selectedIds"
            @updateSelectedUserIds="updateSelectedUsers"
            @updateCheckedTeams="updateCheckedTeams"
            />
    </div>

    <!----------------- ADD PARTICIPANTS ----------------->
    <div class="select-container">
      <!-- eslint-disable-next-line max-len -->
      <div class="participants-container" ref="participants-container" id="btn_select" @click.prevent="toggleParticipants">
        <div>
          <span class="circle-plus" :class="{'color-disabled': editable === false}">
            <font-awesome-icon icon="fa-circle-plus"/>
          </span>
          <span class="btn-text" id="btn_textParticipants">{{ participantsText }}</span>
        </div>
        <div v-if="!showParticipants" class="pill-container">
          <div v-for="(selectedUser) in getSelectedUsers" :key="selectedUser"
               class="pill-element" :class="{'bg-disabled': editable === false}">
            {{ selectedUser.name }}
          </div>
        </div>
      </div>
      <edit-kss-participants-selection-component
        :involvedUsers="involvedUsers"
        :allKssParticipants="allKssParticipants"
        :selectedIds="selectedIds"
        @updateSelectedUserIds="updateSelectedUsers"
        v-if="showParticipants"/>
    </div>
    <!-------------- ADD EXTERNAL PARTICIPANTS ----------->
    <div class="select-container">
      <!-- eslint-disable-next-line max-len -->
      <div class="email-container" ref="email-container" id="email_invite" @click.prevent="toggleEmailInvite">
        <div>
           <span class="circle-plus" :class="{'color-disabled': editable === false}">
              <font-awesome-icon icon="fa-circle-plus"/>
            </span>
          <span class="btn-text" id="email_inviteParticipants">
          {{ $t('ExternalUsersInviteComponent.externalInvites') }}
        </span>
        </div>
        <div v-if="!showEmailInvite" class="pill-container">
          <div v-for="(externalUser) in externalUsers" :key="externalUser"
               class="pill-element" :class="{'bg-disabled': editable === false}">
            {{ externalUser }}
          </div>
        </div>
      </div>
      <edit-external-users-invite-component v-if="showEmailInvite"
                                       :involvedTaskUsers="involvedUsers"
                                       :allInvolvedKssUsers="allInvolvedKssUsers"
                                       :externalUsers="externalUsers"
                                       @updateExternalUsers="updateExternalUsers"/>
    </div>
  </div>
</template>

<script>
import EditKssTeamSelectionComponent from '@/components/Home/KssCreator/KssEditComponents/EditKssTeamSelectionComponent.vue';

import EditKssParticipantsSelectionComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssParticipantsSelectionComponent.vue';
import EditExternalUsersInviteComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditExternalUsersInviteComponent.vue';

export default {
  props: {
    editable: {
      type: Boolean,
    },
    initialSubject: {
      type: String,
      default: null,
    },
    initialDescription: {
      type: String,
      default: null,
    },
    involvedUsers: {
      type: Array,
      default: null,
    },
    allKssParticipants: {
      type: Array,
      default: null,
    },
    allInvolvedKssUsers: {
      type: Array,
      default: null,
    },
    selectedIds: {
      type: Array,
      default: null,
    },
    externalUsers: {
      type: Array,
      default: null,
    },
    KssTeams: {
      type: Array,
      default: null,
    },
  },
  name: 'EditKssFormComponent',
  components: {
    EditExternalUsersInviteComponent,
    EditKssParticipantsSelectionComponent,
    EditKssTeamSelectionComponent,
  },
  data() {
    return {
      showTeams: false,
      showParticipants: false,
      showEmailInvite: false,
      meetingSubject: this.initialSubject,
      description: this.initialDescription,
      countSelectedParticipants: 0,
      selectedTeams: [],
      checkedKssTeams: [],
    };
  },
  computed: {
    participantsText() {
      if (this.countSelectedParticipants > 0) {
        return `${this.countSelectedParticipants} ${this.$t('KssFormComponent.selected')}`;
      }
      return this.$t('KssFormComponent.addParticipants');
    },
    getSelectedUsers() {
      return this.allKssParticipants
        .filter((el) => this.selectedIds.includes(el._id));
    },
    subjectComputed: {
      get() {
        return this.meetingSubject;
      },
      set(val) {
        this.meetingSubject = val;
        this.$emit('updateSubject', val);
      },
    },
    descriptionComputed: {
      get() {
        return this.description;
      },
      set(val) {
        this.description = val;
        this.$emit('updateDescription', val);
      },
    },
  },
  methods: {
    debounce(func, delay) {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    adjustParticipantContainerHeight() {
      const participantContainer = this.$refs['participants-container'];
      const resize = () => {
        if (participantContainer.clientHeight >= 60) {
          participantContainer.style.flexDirection = 'column';
          participantContainer.style.alignItems = 'flex-start';
        } else {
          participantContainer.style.flexDirection = 'row';
          participantContainer.style.alignItems = 'center';
        }
      };
      resize();
      const resizeHandler = this.debounce(() => resize(), 100);
      const resizeObserver = new ResizeObserver(resizeHandler);
      resizeObserver.observe(participantContainer);
    },
    adjustEmailContainerHeight() {
      const emailContainer = this.$refs['email-container'];
      const resize = () => {
        if (emailContainer.clientHeight >= 60) {
          emailContainer.style.flexDirection = 'column';
          emailContainer.style.alignItems = 'flex-start';
        } else {
          emailContainer.style.flexDirection = 'row';
          emailContainer.style.alignItems = 'center';
        }
      };
      resize();
      const resizeHandler = this.debounce(() => resize(), 100);
      const resizeObserver = new ResizeObserver(resizeHandler);
      resizeObserver.observe(emailContainer);
    },
    adjustTeamsContainerHeight() {
      const teamsContainer = this.$refs['teams-container'];
      const resize = () => {
        if (teamsContainer.clientHeight >= 60) {
          teamsContainer.style.flexDirection = 'column';
          teamsContainer.style.alignItems = 'flex-start';
        } else {
          teamsContainer.style.flexDirection = 'row';
          teamsContainer.style.alignItems = 'center';
        }
      };
      resize();
      const resizeHandler = this.debounce(() => resize(), 100);
      const resizeObserver = new ResizeObserver(resizeHandler);
      resizeObserver.observe(teamsContainer);
    },
    toggleTeams() {
      this.showTeams = !this.showTeams;
    },
    toggleParticipants() {
      if (this.editable === false) return;
      this.showParticipants = !this.showParticipants;
    },
    toggleEmailInvite() {
      if (this.editable === false) return;
      this.showEmailInvite = !this.showEmailInvite;
    },
    updateSelectedUsers(ids) {
      this.countSelectedParticipants = ids.length;
      this.$emit('updateSelectedUserIds', ids);
    },
    updateExternalUsers(externalUsers) {
      this.$emit('updateExternalUsers', externalUsers);
    },
    updateCheckedTeams(checkedTeams) {
      this.selectedTeams = checkedTeams.filter((el) => el.isChecked);
      this.checkedKssTeams = checkedTeams;
    },
  },
  created() {
    this.countSelectedParticipants = this.allKssParticipants
      .filter((el) => this.involvedUsers.includes(el._id)).length;

    this.KssTeams.forEach((team) => {
      const teamObject = { ...team };
      teamObject.isChecked = false;
      this.checkedKssTeams.push(teamObject);
    });
  },
  mounted() {
    this.adjustTeamsContainerHeight();
    this.adjustParticipantContainerHeight();
    this.adjustEmailContainerHeight();
  },
};
</script>

<style scoped>
/*---------------- CONTENT NEW: CREATOR ------------------*/
.inputs {
  margin-top: 1.5rem;
}

input[type=text] {
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  height: 3rem;
  margin-right: 50px;
  font-size: 15px;
}

.input-field {
  transition: all .3s;
  height: 3.5rem;
}

.inputs .inputlabel {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  opacity: 1;
  transform: translateY(-2.3rem);
  transform-origin: 0 0;
  transition: all .3s;
  padding-left: var(--card-padding);
}

.form-control::placeholder {
  color: transparent;
}

.form-control {
  transition: all .5s;
}

.form-control:focus {
  border-color: var(--color-orange);
}

.form-control:focus + label,
.form-control:not(:placeholder-shown) + label {
  transform: translateY(-3rem) scale(.8); /*formatiert Label nach links oben und reduziert Größe*/
}

.input-field:focus-within {
  transform: scale(1.02, 1.02);
}

/*------------------ ADD PARTICIPANTS -------------------*/
.select-container {
  position: relative;
  width: 100%;
  margin-top: 8px;
}

.teams-container {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  align-items: center;
  min-height: 40px;
  padding: 2px;
}

.teams-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  margin-left: 10px;
}

.teams-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  margin: 10px;;
}

.teams-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.teams-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.teams-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.participants-container {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  align-items: center;
  min-height: 40px;
  padding: 2px;
}

.participants-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  margin-left: 10px;
}

.participants-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  margin: 10px;
}

.participants-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.participants-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.participants-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.email-container {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  align-items: center;
  min-height: 40px;
  padding: 2px;
}

.email-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  margin-left: 10px;
}

.email-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  margin: 10px;
}

.email-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.email-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.email-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.list-items {
  width: 100%;
  max-height: 0;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: var(--color-milk);
  display: none;
  overflow: hidden;
  z-index: 10;
}

.list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .item-text {
  display: flex;
  align-items: center;
}

.list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 21px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

/*.item.checked .check-icon{
    transform: scale(1);
}*/

.pill-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.pill-element {
  display: flex;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
  color: var(--color-milk);
}
.color-disabled {
  color: var(--color-disabled) !important;
}
.bg-disabled {
  background-color: var(--color-disabled) !important;
}

@media (max-width: 720px) {
  .participants-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
