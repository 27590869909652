<template>
  <div class="mainEmployeeSatisfaction">
    <div class="upperEmployeeSatisfaction">
      <FiltersComponent
        :allowFilter="{
          team: true,
          tag: true
        }"
        :max="employeeSatisfactionMaxSelection"
        :multiple="false"
        :modelValue="selections"
        @update:modelValue="saveSelection"
      />
    </div>
    <MessageBoxComponent is-warning :text="employeeSatisfactionWarning" />
    <div class="lowerEmployeeSatisfaction">
      <div class="leftEmployeeContainer">
        <div class="topSatisfactionChartContainer">
          <h1>{{ $t('mngDashboardComponent.employeeSatisfactionOverTime') }}</h1>
          <select v-model="employeeSelectFilter" @change="applyEmployeeFilter">
            <option
              v-for="item in employeeSatisfactionPeriod"
              :key="item?.identifier"
              :value="item?.identifier"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="satisfactionChartContainer">
          <EmployeeSatisfactionChart ref="employeeSatisfaction" />
        </div>
      </div>
      <div class="rightEmployeeContainer">
        <div class="avgChartContainer" v-show="showAverageChart">
          <div class="topAvgEmployeeContainer">
            <h1>{{ $t('mngDashboardComponent.avgEmployeeSatisfaction') }}</h1>
            <select v-model="avgEmployeeSelectFilter" @change="applyAvgEmployeeFilter">
              <option
                v-for="item in employeeSatisfactionPeriod"
                :key="item?.identifier"
                :value="item?.identifier"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="avgEmployeeSatChart"
          tabindex="0">
            <AvgEmployeeSatisfactionChart ref="avgEmployeeSatisfaction" />
          </div>
        </div>
        <div
          v-if="employeeSatisfactionText?.headline || employeeSatisfactionText?.text"
          class="employeeTextContainer">
          <div style="display: flex">
            <img src="@/assets/light.svg" alt="light" />
            <h1 v-html="employeeSatisfactionText?.headline"></h1>
          </div>
          <p v-html="employeeSatisfactionText?.text"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../managementDashboard.css';
import EmployeeSatisfactionChart from '@/components/Home/ManagementDashboard/SatisfactionReportComponent/EmployeeSatisfactionChartComponent.vue';
import AvgEmployeeSatisfactionChart from '@/components/Home/ManagementDashboard/SatisfactionReportComponent/AvgEmployeeSatisfactionChartComponent.vue';
import FiltersComponent from '@/components/Home/ManagementDashboard/common/FiltersComponent.vue';
import MessageBoxComponent from '@/components/Home/ManagementDashboard/common/MessageBoxComponent.vue';

export default {
  name: 'SatisfactionReportComponent',
  data() {
    return {
      employeeSelectFilter: 'Last7Days',
      avgEmployeeSelectFilter: 'Last7Days',
      showAverageChart: true,
    };
  },
  components: {
    EmployeeSatisfactionChart,
    AvgEmployeeSatisfactionChart,
    FiltersComponent,
    MessageBoxComponent,
  },
  computed: {
    selections: {
      get() {
        return this.$store.getters.getSelections;
      },
      set(value) {
        this.$store.commit('setSelections', value);
      },
    },
    employeeSatisfactionData() {
      return this.$store.getters.getMoodomat?.employeeSatisfactionData;
    },
    avgEmployeeSatisfactionData() {
      return this.$store.getters.getMoodomat?.avgEmployeeSatisfactionData;
    },
    employeeSatisfactionText() {
      return this.$store.getters.getMoodomat?.employeeSatisfactionText;
    },
    employeeSatisfactionPeriod() {
      return this.$store.getters.getMoodomat?.employeeSatisfactionPeriod;
    },
    employeeSatisfactionMaxSelection() {
      return this.$store.getters.getMoodomat?.maxSelection;
    },
    employeeSatisfactionWarning() {
      return this.$store.getters.getMoodomat?.warning;
    },
  },
  methods: {
    // --------- Main Function to call the api on every filters change ---------------- //
    async employeeOverAllFunction(isEmployee) {
      try {
        await this.$store.dispatch('fetchInitialEmployeeSatisfactionData', {
          selections: this.selections,
          employeePeriodType: this.employeeSelectFilter,
          avgEmployeePeriodType: this.avgEmployeeSelectFilter,
        });
        if (isEmployee === 'isEmployee') {
          this.$refs.employeeSatisfaction?.employeeSatisfactionLineChart(
            this.employeeSatisfactionData,
          );
        } else if (isEmployee === 'isAvgEmployee') {
          this.$refs.avgEmployeeSatisfaction?.avgEmployeeSatisfactionBarChart(
            this.avgEmployeeSatisfactionData,
          );
        } else {
          this.$refs.employeeSatisfaction?.employeeSatisfactionLineChart(
            this.employeeSatisfactionData,
          );
          this.$refs.avgEmployeeSatisfaction?.avgEmployeeSatisfactionBarChart(
            this.avgEmployeeSatisfactionData,
          );
        }
      } catch (error) {
        console.error(error);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
      }
    },
    // --------------- Filters---------------- //
    applyEmployeeFilter() {
      this.employeeOverAllFunction('isEmployee');
    },
    applyAvgEmployeeFilter() {
      this.employeeOverAllFunction('isAvgEmployee');
    },
    saveSelection(selection) {
      this.selections = selection;
    },
  },
  watch: {
    selections() {
      this.employeeOverAllFunction('');
    },
  },

  mounted() {
    this.employeeOverAllFunction('');
  },
};
</script>
