<template>
  <p class="headline" :class="{'color-disabled': editable === false}"
   id="txt_time">{{ updateText }}</p>
  <div class="timer">
    <vee-field v-model="time"
               :readonly="editable === false"
               type="time"
               class="time"
               :class="{'color-disabled': editable === false}"
               name="start-time"
               id="input_cal-time"/>
  </div>
  <edit-kss-recurring-task-component :initialRecurring="initialRecurring"
                                     :editable="editable"
                                     @updateReccuring="updateReccuring"/>
</template>

<script>
import EditKssRecurringTaskComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssRecurringTaskComponent.vue';

export default {
  props: {
    editable: {
      type: Boolean,
    },
    initialDate: {
      type: Date,
      default: null,
    },
    initialRecurring: {
      type: Object,
      default: null,
    },
    isStartTime: {
      type: Boolean,
    },
  },
  emits: ['updateTime', 'updateReccuring'],
  name: 'EditKssRuntimeComponent',
  components: { EditKssRecurringTaskComponent },
  data() {
    return {
      time: null,
    };
  },
  computed: {
    updateText() {
      if (this.isStartTime === 'true') {
        return this.$t('KssRuntimeComponent.headingStartRuntime');
      }
      return this.$t('KssRuntimeComponent.headingRuntime');
    },
  },
  methods: {
    updateReccuring(value) {
      this.$emit('updateReccuring', value);
    },
  },
  watch: {
    time(newTime) {
      this.$emit('updateTime', newTime);
    },
  },
  mounted() {
    if (this.initialDate) {
      this.time = `${(`0${this.initialDate.getHours()}`).slice(-2)}:${(`0${this.initialDate.getMinutes()}`).slice(-2)}`;
    } else {
      this.time = `${(`0${new Date().getHours()}`).slice(-2)}:${(`0${new Date().getMinutes()}`).slice(-2)}`;
    }
  },
};
</script>

<style scoped>
.headline {
  color: var(--color-orange);
  font-size: 1.5rem;
  text-align: center;
}

.timer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.timer .time {
  font-size: 30px;
  font-weight: 700;
  /* padding: var(--card-padding) 0 0 0;*/
}

.start-runtime {
  display: block;
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.start-runtime:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.warning-note {
  /* width: 75%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.warning-note span {
  font-size: 13px;
  padding-left: 15px;
}

.warning-note i {
  font-size: 27px;
  color: var(--color-error);
}
.color-disabled {
  color: var(--color-disabled) !important;
}
</style>
