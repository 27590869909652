<template>
  <div class="time-wrapper">
    <div class="time-box">
      <label for="cbox1" class="label" @click.prevent="toggleShowCalendar">
        <font-awesome-icon icon="fa-calendar" />
        {{ formattedStartDate ?? $t("KssRuntimeComponent.selectDate") }}
      </label>
      <div class="area" id="div_calendar-date" v-if="getShowCalendar">
        <edit-kss-calendar-component
          :editable="true"
          :initialDate="selectedDate"
          @updateDate="updateSelectedDate"
        />
      </div>
      <label for="cbox2" class="label" @click.prevent="toggleShowRuntime">
        <font-awesome-icon icon="fa-clock" />
        {{ formattedTime ?? $t("KssRuntimeComponent.selectRuntime") }}
      </label>
      <div class="area" id="div_calendar-time" v-if="getShowRuntime">
        <!----------------- RUNTIME ----------------->
        <div class="runtime">
          <edit-kss-runtime-component
            :editable="true"
            :initialDate="getRuntimeDate"
            @updateTime="updateSelectedTime"
            :is-start-time="isStartTime"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditKssCalendarComponent from '@/components/Home/KssCreator/KssEditComponents/EditKssCalendarComponent.vue';
import EditKssRuntimeComponent from '@/components/Home/KssCreator/KssEditComponents/EditKssRuntimeComponent.vue';

export default {
  name: 'DateTimeSelectionComponent',
  components: {
    EditKssRuntimeComponent,
    EditKssCalendarComponent,
  },
  props: {
    modelValue: Date,
    isStartTime: Boolean,
  },
  data() {
    return {
      showRuntime: false,
      showCalendar: false,
      selectedHours: null,
      selectedMinutes: null,
      selectedDate: this.modelValue,
    };
  },
  computed: {
    formattedStartDate() {
      if (!this.selectedDate) {
        return '';
      }
      return `${`0${this.selectedDate.getDate()}`.slice(-2)}.${`0${
        this.selectedDate.getMonth() + 1
      }`.slice(-2)}.${this.selectedDate.getFullYear()}`;
    },
    formattedTime() {
      return `${`0${this.selectedHours}`.slice(-2)}:${`0${this.selectedMinutes}`.slice(-2)}`;
    },
    getRuntimeDate() {
      return new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        this.selectedDate.getDate(),
        this.selectedHours,
        this.selectedMinutes,
      );
    },
    getShowCalendar() {
      return this.showCalendar;
    },
    getShowRuntime() {
      return this.showRuntime;
    },
  },
  methods: {
    updateSelectedDate(date) {
      this.selectedDate = date;
      this.$emit('update:modelValue', this.getRuntimeDate);
    },
    updateSelectedTime(time) {
      const hours = time.split(':')[0];
      const minutes = time.split(':')[1];
      this.selectedHours = Number(hours);
      this.selectedMinutes = Number(minutes);
      this.$emit('update:modelValue', this.getRuntimeDate);
    },
    toggleShowCalendar() {
      this.showCalendar = !this.showCalendar;
      this.showRuntime = false;
    },
    toggleShowRuntime() {
      this.showRuntime = !this.showRuntime;
      this.showCalendar = false;
    },
  },
  mounted() {
    this.selectedHours = new Date(this.selectedDate).getHours();
    this.selectedMinutes = new Date(this.selectedDate).getMinutes();
  },
};
</script>

<style scoped>
.time-wrapper {
  /*min-height: 100vh;*/
  box-sizing: border-box;
  padding: 0px;
  max-width: 960px;
  margin: 0 auto;
}

.time-wrapper {
  /*min-height: 100vh;*/
  box-sizing: border-box;
  padding: 0px;
  max-width: 960px;
  margin: 0 auto;
}

.time-wrapper .time-box {
  display: flex;
  flex-wrap: wrap;
}

.time-box .label {
  text-align: center;
  display: block;
  width: 350px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: var(--color-orange);
  background-color: var(--color-white);
  cursor: pointer;
}

.time-box .area {
  order: 99;
  flex-grow: 1;
  width: 100%;
}

.runtime {
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--color-milk);
}

.expandable {
  z-index: 20;
  cursor: default;
}

/*------------------ BUTTONS -------------------*/
.btns {
  margin-top: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.btn-update {
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-update:hover {
  transition: 0.5s;
  background-color: var(--color-fulvous);
}

i.disabled {
  cursor: not-allowed;
  color: var(--color-disabled);
}

i.disabled:hover {
  color: var(--color-disabled) !important;
}

button.disabled {
  cursor: not-allowed;
}

button.disabled:hover {
  background-color: var(--color-bisque);
}

</style>
