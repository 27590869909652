<template>
  <div class="task-card"
       :class="{'task-card__disabled': editable === false && showContainer === false,
           'task-item__disabled': editable === false && showContainer === true}">
    <div @click.prevent="toogleDetails">
      <div class="headline">
        <div class="task-info">
          <h2>
            {{ $t("KssTaskCreatedComponent.collaborationTracker", { name: createdTask?.name }) }}
          </h2>
          <div>
            <i v-if="isRecurring" class="recurring-icon">
              <font-awesome-icon icon="fa-repeat"/>
            </i>
            <label v-if="isRecurring">{{ determineType() }}</label>
          </div>
        </div>
        <div class="delete">
          <i v-if="editable !== false">
            <font-awesome-icon icon="fa-pen"/>
          </i>
          <i
            class="icon"
            @click.stop="deleteTask"
          >
            <font-awesome-icon icon="fa-trash-can"/>
          </i>
        </div>
      </div>
      <div class="participants-status" v-if="usersCount > 0">
        <i class="icon">
          <font-awesome-icon icon="fa-users"/>
        </i>
        <p class="participants-label">{{
            participantsCount + ' ' + $tc('KssTaskCreatedComponent.participants', participantsCount)
          }} |</p>
        <p class="participants-percent" :style="available">{{ participantsPercent }}</p>
      </div>
      <div class="status">
        <div class="time-rubber">
          <i class="icon">
            <font-awesome-icon icon="fa-calendar"/>
          </i>
          <p class="labelname">{{ formattedDate }}</p>
        </div>
        <div v-if="createdTask?.outlook_id"
             class="guideline_text">{{ $t('kss_guideline_text') }}
        </div>
        <div>
          <div v-if="createdTask?.outlook_id" class="time-rubber">
            <p class="labelname">Outlook</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showContainer" class="expandable">
      <div class="content">
        <div class="forms">
          <vee-form @submit="updateKssTask">
            <div class="time-wrapper">
              <div class="time-box">
                <label for="cbox1" class="label" id="lbl_calDate"
                       @click.prevent="toggleShowCalendar">
                  <font-awesome-icon icon="fa-calendar"/>
                  {{ formattedStartDate ?? $t('KssRuntimeComponent.selectDate') }}
                </label>
                <div class="area" id="div_calendar-date" v-if="getShowCalendar">
                  <edit-kss-calendar-component v-if="createdTask" :editable="editable"
                                               :initialDate="selectedDate"
                                               @updateDate="updateSelectedDate"/>
                </div>
                <label for="cbox2" class="label" id="lbl_cal-time"
                       @click.prevent="toggleShowRuntime">
                  <font-awesome-icon icon="fa-clock"/>
                  {{ formattedTime ?? $t('KssRuntimeComponent.selectRuntime') }}
                </label>
                <div class="area" id="div_calendar-time" v-if="getShowRuntime">
                  <!----------------- RUNTIME ----------------->
                  <div class="runtime" id="div_runtime">
                    <edit-kss-runtime-component v-if="createdTask"
                                                :editable="editable"
                                                :initialDate="getRuntimeDate"
                                                :initialRecurring="createdTask.repeatRules[0]"
                                                @updateTime="updateSelectedTime"
                                                @updateReccuring="updateReccuring"
                                                :is-start-time="false"/>
                  </div>
                </div>
              </div>
            </div>
            <edit-kss-form-component :editable="editable"
                                     :initialSubject="createdTask.name"
                                     :initialDescription="createdTask.description"
                                     :involvedUsers="createdTask.involvedUsers"
                                     :allKssParticipants="allKssParticipants"
                                     :allInvolvedKssUsers="involvedKssUsers"
                                     :selectedIds="selectedUsers"
                                     :externalUsers="externalUsers"
                                     :KssTeams="KssTeams"
                                     @updateSubject="updateSubject"
                                     @updateDescription="updateDescription"
                                     @updateSelectedUserIds="updateSelectedUsers"
                                     @updateExternalUsers="updateExternalUsers"/>
          </vee-form>
        </div>
        <div v-if="editable !== false" class="btns">
          <button type="button" class="btn-update" name="update" :disabled="editable === false"
                  :class="editable === false && 'disabled'"
                  @click.prevent="updateKssTask">
            {{ $t('KssTaskCreatedComponent.updateTask') }}
          </button>
        </div>
      </div>
      <div class="next-four-events-component" v-if="this.isTaskScheduledToReoccur">
        <h2>
          {{
            $t("KssTaskCreatedComponent.nextEvents", { count: this.currentTaskEvents.length || '' })
          }}
        </h2>
        <div class="next-four-events-dates">
          <div
            class="pill-element"
            v-for="taskEvent in currentTaskEvents"
            :key="taskEvent"
            @click="handleCancelledTaskEventsAction(taskEvent)"
            :class="{ grayDate: isCancelledTaskEvent(taskEvent) }"
          >
            {{ getFormattedDate(taskEvent.date_eventBegin) }}
            <font-awesome-icon class="pill-icon" v-if="isCancelledTaskEvent(taskEvent)"
                               icon="fa-arrows-rotate"/>
            <font-awesome-icon class="pill-icon" v-else icon="fa-trash-can"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pop-up-confirmation-component
    v-if="showPopup"
    :message="popupMessage"
    :confirmButtonText="deleteButtonText"
    :cancelButtonText="cancelButtonText"
    @cancel="onPopupCancel"
    @confirm="onPopupConfirm"
  />
</template>

<script>
import PopUpConfirmationComponent from '@/components/global/PopUpConfirmationComponent.vue';
import EditKssCalendarComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssCalendarComponent.vue';
import EditKssRuntimeComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssRuntimeComponent.vue';
import EditKssFormComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssFormComponent.vue';

export default {
  name: 'KssTaskCreatedComponent',
  components: {
    EditKssFormComponent,
    EditKssRuntimeComponent,
    EditKssCalendarComponent,
    PopUpConfirmationComponent,
  },
  props: ['createdTask', 'allKssParticipants', 'involvedKssUsers'],
  data() {
    return {
      currentTaskEvents: this.createdTask.taskEvents
        .filter((e) => e.status === 'scheduled' || e.status === 'removed_from_series')
        .sort(
          (a, b) => new Date(a.date_eventBegin).getTime() - new Date(b.date_eventBegin).getTime(),
        ),
      showPopup: false,
      showContainer: false,
      showRuntime: false,
      showCalendar: false,
      selectedHours: null,
      selectedMinutes: null,
      selectedDate: null,
      repeatRules: this.createdTask.repeatRules,
      reccurringObject: null,
      selectedUsers: this.createdTask.involvedUsers,
      meetingSubject: this.createdTask.name,
      meetingDescription: this.createdTask.description,
      KssTeams: this.$store.getters.getTeams,
      externalUsers: [],
      popupMessage: this.$t('KssTaskCreatedComponent.popup.deleteMessage'),
      deleteButtonText: this.$t('KssTaskCreatedComponent.popup.deleteButton'),
      cancelButtonText: this.$t('KssTaskCreatedComponent.popup.cancelButton'),
      usersCount: this.createdTask?.involvedUsers?.length || 0,
      isRecurring: this.createdTask.isRecurring,
      startDate: null,
      minCount: this.$store.getters.getMinCount,
    };
  },
  computed: {
    editable() {
      return this.createdTask.editable;
    },
    formattedDate() {
      if (!this.selectedDate) {
        return '';
      }
      return `${(`0${this.selectedDate.getDate()}`).slice(-2)}.${(`0${this.selectedDate.getMonth() + 1}`).slice(-2)}.${this.selectedDate.getFullYear()}`;
    },
    isTaskScheduledToReoccur() {
      if (this.createdTask.repeatRules[0].frequency === 'MINUTELY') {
        return false;
      }
      return true;
    },
    formattedStartDate() {
      if (!this.startDate) {
        return '';
      }
      return `${(`0${this.startDate.getDate()}`).slice(-2)}.${(`0${this.startDate.getMonth() + 1}`).slice(-2)}.${this.startDate.getFullYear()}`;
    },
    formattedTime() {
      // should be able to see 00 hours selected
      /* if (!this.selectedHours && !this.selectedHours) {
        return '';
      } */
      return `${(`0${this.selectedHours}`).slice(-2)}:${(`0${this.selectedMinutes}`).slice(-2)}`;
    },
    getRuntimeDate() {
      return new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        this.selectedDate.getDate(),
        this.selectedHours,
        this.selectedMinutes,
      );
    },
    getShowCalendar() {
      return this.showCalendar;
    },
    getShowRuntime() {
      return this.showRuntime;
    },
    participantsCount() {
      return this.createdTask.involvedUsers.length;
    },
    participantsPercent() {
      if (this.usersCount >= this.minCount) {
        return `${Number(this.createdTask.percent).toFixed(2)}% completed`;
      }
      return this.$t('KssTaskArchivedComponent.lessParticipants', { count: this.minCount });
    },
    available() {
      if (this.usersCount >= this.minCount) {
        return 'color: var(--color-orange)';
      }
      return 'color: var(--color-disabled)';
    },
  },
  methods: {
    updateSelectedDate(date) {
      this.selectedDate = date;
    },
    updateSelectedUsers(users) {
      this.selectedUsers = users;
    },
    updateSelectedTime(time) {
      const hours = time.split(':')[0];
      const minutes = time.split(':')[1];
      this.selectedHours = Number(hours);
      this.selectedMinutes = Number(minutes);
    },
    updateReccuring(reccuringObject) {
      this.reccurringObject = reccuringObject;
    },
    updateSubject(subject) {
      this.meetingSubject = subject;
    },
    updateDescription(description) {
      this.meetingDescription = description;
    },
    updateExternalUsers(externalUsers) {
      this.externalUsers = externalUsers;
    },
    toogleDetails() {
      this.showContainer = !this.showContainer;
    },
    toggleShowCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    toggleShowRuntime() {
      this.showRuntime = !this.showRuntime;
    },
    deleteTask() {
      this.showPopup = true;
    },
    onPopupCancel() {
      this.showPopup = false;
    },
    // added additional checks to allow to set time to 00 hours
    checkForErrors() {
      let hasError = false;

      if (!this.selectedDate) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.selectDateErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (!this.selectedHours && this.selectedHours !== 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.selectTimeErr'),
          type: 'error',
        });
        hasError = true;
      }

      // allow only recurring tasks in the past to be edited
      // non recurring tasks should show an error if edited in the past
      if (this.selectedDate
        && (this.selectedHours || this.selectedHours === 0)
        && !this.checkDateAndTimeValid()
        && this.createdTask.repeatRules[0].frequency === 'MINUTELY') {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.pastDateErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (!this.meetingSubject) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.selectSubjectErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.selectedUsers.length === 0
        && this.externalUsers.length === 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.selectParticipantsErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.reccurringObject !== null) {
        if (this.reccurringObject.isReccuring) {
          if (this.reccurringObject.frequency === 'WEEKLY') {
            if (this.reccurringObject.byDayOfWeek.length === 0) {
              this.$store.commit('setToastNotification', {
                id: Math.random(),
                message: this.$t('ToastComponent.kss.selectRecurringDays'),
                type: 'error',
              });
              hasError = true;
            }
          }
        }
      }

      return hasError;
    },
    checkDateAndTimeValid() {
      const selectedDate = new Date(this.selectedDate);
      selectedDate.setHours(this.selectedHours, this.selectedMinutes);

      const currentTime = new Date();
      const timeDifference = currentTime - selectedDate;
      return timeDifference <= 60 * 60 * 1000;
    },
    buildRepeatRules(dateTaskBegin) {
      if (this.reccurringObject === null) {
        this.repeatRules[0].start = dateTaskBegin;

        if (Object.prototype.hasOwnProperty.call(this.repeatRules[0], 'end')) {
          this.repeatRules[0].end = dateTaskBegin;
        }
        return this.repeatRules;
      }

      if (!this.reccurringObject.isReccuring) {
        return [{
          frequency: 'MINUTELY',
          start: dateTaskBegin,
          end: dateTaskBegin,
        }];
      }

      const tempReccuringObj = { ...this.reccurringObject };
      delete tempReccuringObj.isReccuring;

      return [{
        ...tempReccuringObj,
        start: dateTaskBegin,
      }];
    },
    async updateKssTask() {
      try {
        if (this.checkForErrors()) {
          return;
        }

        const dateTaskBegin = new Date(
          this.selectedDate.getFullYear(),
          this.selectedDate.getMonth(),
          this.selectedDate.getDate(),
          this.selectedHours,
          this.selectedMinutes,
        );

        const repeatRules = this.buildRepeatRules(dateTaskBegin.toUTCString());

        // this is needed to filter out the external users from the selected ids
        const externalIds = this.involvedKssUsers
          .filter((el) => el.userStatus === 'external')
          .map((el) => el._id);

        const data = {
          id: this.createdTask._id,
          name: this.meetingSubject,
          description: this.meetingDescription,
          involvedUsers: this.selectedUsers.filter((el) => !externalIds.includes(el)),
          involvedExternalUsers: this.externalUsers,
          repeatRules,
        };
        await this.$store.dispatch('updateKssTask', data);

        await this.$store.dispatch('getCreatedTasks');

        this.showContainer = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.kssUpdated'),
          type: 'success',
        });
        this.$store.dispatch('getCreatedTasks');
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    async onPopupConfirm() {
      const data = {
        taskID: this.createdTask._id,
      };
      try {
        await this.$store.dispatch('deleteKssTask', data);
        this.showPopup = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('KssTaskCreatedComponent.toast.successfulDelete'),
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('KssTaskCreatedComponent.toast.errorDelete'),
          type: 'error',
        });
      }
    },
    determineType() {
      if (Object.prototype.hasOwnProperty.call(this.createdTask.repeatRules[0], 'interval')) {
        return this.$t('KssRecurringTaskComponent.custom');
      }

      if (this.createdTask.repeatRules[0].frequency === 'MONTHLY') {
        return this.$t('KssRecurringTaskComponent.monthly');
      }

      if (this.createdTask.repeatRules[0].frequency === 'WEEKLY') {
        return this.$t('KssRecurringTaskComponent.weekly');
      }

      return this.$t('KssRecurringTaskComponent.daily');
    },
    getFormattedDate(date) {
      const newDate = new Date(date);
      return `${`0${newDate.getDate()}`.slice(-2)}.${`0${newDate.getMonth() + 1}`.slice(
        -2,
      )}.${newDate.getFullYear()}`;
    },
    isCancelledTaskEvent(taskEvent) {
      return taskEvent.status === 'removed_from_series';
    },
    async handleCancelledTaskEventsAction(taskEvent) {
      try {
        const index = this.currentTaskEvents.findIndex(
          (t) => t._id === taskEvent._id,
        );

        let message = this.$t('KssTaskCreatedComponent.toast.successfulCancelDate');
        if (this.currentTaskEvents[index].status === 'scheduled') {
          this.currentTaskEvents[index].status = 'removed_from_series';
        } else {
          message = this.$t('KssTaskCreatedComponent.toast.successfulScheduleDate');
          this.currentTaskEvents[index].status = 'scheduled';
        }

        const taskEventData = {
          taskId: this.createdTask._id,
          taskEventId: taskEvent._id,
          status: this.currentTaskEvents[index].status,
        };
        await this.$store.dispatch('updateTaskEventsStatus', taskEventData);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message,
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('KssTaskCreatedComponent.toast.errorDelete'),
          type: 'error',
        });
      }
    },
  },
  mounted() {
    this.involvedKssUsers.forEach((el) => {
      if (el.userStatus === 'external' && this.createdTask.involvedUsers.includes(el._id)) {
        this.externalUsers.push(el.email);
      }
    });
    this.selectedHours = new Date(this.createdTask.repeatRules[0].start).getHours();
    this.selectedMinutes = new Date(this.createdTask.repeatRules[0].start).getMinutes();
    let selectedDate = '';
    const tasks = this.createdTask.taskEvents;
    tasks.every((task, index) => {
      if (task.status === 'open' || task.status === 'processed') {
        selectedDate = new Date(task.date_eventBegin);
        return false;
      }
      // this is the first event
      if (task.status === 'scheduled') {
        selectedDate = new Date(task.date_eventBegin);
        return false;
      }
      // only one finished event
      if (task.status === 'finished' && tasks.length === 1) {
        selectedDate = new Date(task.date_eventBegin);
        return false;
      }
      // have more events, so display the recently finished one
      if (task.status === 'finished' && tasks[index + 1].status === 'scheduled') {
        selectedDate = new Date(task.date_eventBegin);
        return false;
      }
      // all events are finished
      if (task.status === 'finished' && tasks[index + 1].status === 'finished' && (index + 2) === tasks.length) {
        selectedDate = new Date(tasks[index + 1].date_eventBegin);
        return false;
      }
      return true;
    });
    this.selectedDate = selectedDate;
    this.startDate = new Date(this.createdTask.repeatRules[0].start);
  },
};
</script>

<style scoped>

.recurring-icon {
  padding-right: 0.75rem;
}

.task-card {
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
}

.task-card__disabled {
  background: var(--color-light-grey) !important;
}

.task-item__disabled .time-rubber {
  background: var(--color-light-grey) !important;
}

.task-item__disabled .time-rubber p,
.task-item__disabled .time-rubber i,
.task-item__disabled label {
  color: var(--color-disabled) !important;
}

.task-card:hover {
  box-shadow: 0 4px 20px 0 #aaa;
  cursor: pointer;
}

.task-card .headline, .task-card .status {
  display: flex;
  justify-content: space-between;
}

.task-card .headline h2 {
  font-size: 23px;
}

h2 {
  font-size: 23px;
}

.task-card .headline .delete i {
  padding-left: 10px;
  font-size: 1.3rem;
  color: var(--color-disabled);
}

.task-card .headline .delete i:hover {
  color: var(--color-orange);
}

.time-rubber {
  display: flex;
  flex-direction: row;
  margin: 0.2rem 0;
  padding: 0.7rem;
  align-items: center;
  height: 2.2rem;
  width: min-content;
  border-radius: 4px;
  background-color: var(--color-orange);
}

.time-rubber .icon {
  display: flex;
  padding: 3px;
  width: 2rem;
  color: var(--color-white);
}

.labelname {
  color: var(--color-white);
  padding: 3px;
}

.forms {
  /* max-width: 90vw; */
  /* width: 600px; */
  /*height: auto;*/
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px;
  background: var(--color-milk);
}

.forms .time-wrapper {
  /*min-height: 100vh;*/
  box-sizing: border-box;
  padding: 0px;
  max-width: 960px;
  margin: 0 auto;
}

.time-wrapper {
  /*min-height: 100vh;*/
  box-sizing: border-box;
  padding: 0px;
  max-width: 960px;
  margin: 0 auto;
}

.time-wrapper .time-box {
  display: flex;
  flex-wrap: wrap;
}

.time-box .label {
  text-align: center;
  display: block;
  width: 350px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: var(--color-orange);
  background-color: var(--color-white);
  cursor: pointer;
}

.time-box .area {
  order: 99;
  flex-grow: 1;
  width: 100%;
}

.runtime {
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--color-milk);
}

.expandable {
  z-index: 20;
  cursor: default;
}

/*------------------ BUTTONS -------------------*/
.btns {
  margin-top: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.btn-update {
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-update:hover {
  transition: 0.5s;
  background-color: var(--color-fulvous);
}

i.disabled {
  cursor: not-allowed;
  color: var(--color-disabled);
}

i.disabled:hover {
  color: var(--color-disabled) !important;
}

button.disabled {
  cursor: not-allowed;
}

button.disabled:hover {
  background-color: var(--color-bisque);
}

.guideline_text {
  text-align: center;
  padding: 4px 20px;
  font-size: 14px;
  font-style: italic;
  color: #aaa;
  display: none;
}

.participants-status {
  height: 2.2rem;
  margin: 0.2rem 0.2rem 0.2rem 0rem;
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.participants-label {
  margin-left: 0.6rem;
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
}

.participants-percent {
  margin-left: 0.6rem;
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
}

.task-card .headline .task-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  word-wrap: break-word;
  word-break: break-all;
}

.task-card .headline .task-info label {
  padding-left: 5px;
  font-size: 0.8rem;
  color: var(--color-orange);
}

.task-card .headline .task-info i {
  padding-left: 15px;
  font-size: 1rem;
  color: var(--color-orange);
}

.task-card .headline .task-info i:hover {
  color: var(--color-orange);
}

@media only screen and (min-width: 720px) {
  .guideline_text {
    display: block;
  }
}

@media (max-width: 520px) {
  .participants-status {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-start;
  }
}

.task-card .headline .task-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.task-card .headline .task-info i {
  padding-left: 1px;
}

/*------------------ NEXT 4 EVENTS -------------------*/
.next-four-events-component {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pill-element {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
  color: var(--color-milk);
}

.pill-icon {
  margin-left: 10px;
}

.grayDate {
  background-color: gray;
}
</style>
