<template>
  <breadcrumbs-component :bread-crumbs="reflexionGuidelineBreadcrumb" />
  <div class="refGuideline-container">
    <div class="wrapper">
      <div class="box">
        <div v-if="reflexionGuidelineAssessments.length > 0">
          <reflexion-guideline-response-component
          v-for="assessment in reflexionGuidelineAssessments" :key="assessment._id"
          :assessment="assessment"></reflexion-guideline-response-component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import ReflexionGuidelineResponseComponent from './ReflexionGuidelineResponseComponent.vue';

export default {
  name: 'ReflexionGuidelineComponent',
  props: [],
  components: { BreadcrumbsComponent, ReflexionGuidelineResponseComponent },
  data() {
    return {
      reflexionGuidelineBreadcrumb: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/reflexionGuideline',
          isActive: true,
          name: this.$t('BreadCrumbs.reflexionGuideline'),
        },
      ],
    };
  },
  computed: {
    reflexionGuidelineAssessments() {
      const reflexionGuideline = this.$store.getters.getReflexionGuidelineAssessments;
      if (!reflexionGuideline || reflexionGuideline.length === 0) {
        return false;
      }
      return reflexionGuideline;
    },
  },
  async mounted() {
    await this.$store.dispatch('getReflexionGuidelineAssessments');
  },
};
</script>
<style scoped>
.refGuideline-container {
  margin: 0 auto;
  position: relative;
}

.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  width: 100vw;
  max-width: 960px;
  margin: 0 auto;
}
.box {
  position: relative;
}</style>
